import { ReactComponent as BlackIcon } from "../../assets/icons/black.svg";
import { ReactComponent as BlueIcon } from "../../assets/icons/blue.svg";
import { ReactComponent as GreenIcon } from "../../assets/icons/green.svg";
import { ReactComponent as RedIcon } from "../../assets/icons/red.svg";
import { ReactComponent as PhoenixIcon } from "../../assets/icons/phoenix.svg";
import { ReactComponent as DragonIcon } from "../../assets/icons/dragon.svg";
import { ReactComponent as DogIcon } from "../../assets/icons/dog.svg";

const ICON_NAMES = ["black", "blue", "green", "red", "phoenix", "dragon", "dog"] as const;
type IconName = (typeof ICON_NAMES)[number];

const icons: Record<IconName, React.FC<React.SVGProps<SVGSVGElement>>> = {
    black: BlackIcon,
    blue: BlueIcon,
    green: GreenIcon,
    red: RedIcon,
    phoenix: PhoenixIcon,
    dragon: DragonIcon,
    dog: DogIcon,
};

interface InlineIconProps {
    iconName: IconName;
    className?: string;
    style?: React.CSSProperties;
}

const InlineIcon = (props: InlineIconProps): JSX.Element => {
    const IconComponent = icons[props.iconName];
    return (
        <span
            className={`inline-icon-wrapper ${props.className ?? ""}`}
            style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                verticalAlign: "-.15em",
                ...props.style,
            }}
        >
            <IconComponent
                style={{
                    width: ".8em",
                    height: "1em",
                    fill: "currentColor",
                }}
            ></IconComponent>
        </span>
    );
};

export default InlineIcon;
export type { IconName };
