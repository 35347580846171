import React from "react";
import styled from "styled-components";
import { UserContext } from "../UserContext";
import { DmTable, TableStatus } from "../client/server-types-python";
import { getName } from "../client/util";

const TablePreviewDiv = styled.div<{ $status: TableStatus }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0px;
    margin: 10px;
    padding: 10px;
    width: 160px;
    height: 160px;
    background-color: ${(props) =>
        props.$status === TableStatus.OPEN
            ? "#d58feb"
            : props.$status === TableStatus.READY
            ? "#8fee97"
            : props.$status === TableStatus.PLAYING
            ? "#e76868"
            : props.$status === TableStatus.LIMBO
            ? "#cf5a91"
            : "#727272"};
    border-radius: 8px;
    position: relative;
`;

const CreateTableButton = styled.button`
    background-color: ${(props) => props.theme.colors.primary_dark};
    border: none;
    border-radius: 6px;
    color: white;
    padding: 8px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    &:hover {
        background-color: ${(props) => props.theme.colors.primary};
    }
    &:active {
        background-color: ${(props) => props.theme.colors.primary_light};
    }
`;

export function TablePreview(props: { table: DmTable; onJoinTable: (tableId: string) => void }): JSX.Element {
    const playerLabels = props.table.players.map((player, index) => <div key={index}>{player ? getName(player) : "---"}</div>);
    const userContext = React.useContext(UserContext);
    const userIsAt = userContext && props.table.players.some((player) => player?.user?.id === userContext.user.id);
    const joinable = (props.table.status === TableStatus.OPEN || props.table.status === TableStatus.LIMBO) && !userIsAt;
    return (
        <TablePreviewDiv $status={props.table.status}>
            <div className="tableStatus">{TableStatus[props.table.status]}</div>
            {playerLabels}
            {joinable && <button onClick={() => props.onJoinTable(props.table.id)}>Join</button>}
        </TablePreviewDiv>
    );
}

export class Lobby extends React.Component<{ tables: DmTable[]; onCreateTable: () => void; onJoinTable: (tableId: string) => void }> {
    render() {
        return (
            <div className="tables">
                <CreateTableButton onClick={() => this.props.onCreateTable()}>Create Table</CreateTableButton>
                {this.props.tables.map((table) => (
                    <TablePreview key={table.id} table={table} onJoinTable={this.props.onJoinTable} />
                ))}
            </div>
        );
    }
}
