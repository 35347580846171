import React from "react";
import styled from "styled-components";
import butterfly from "../assets/butterfly.svg";
import TAICHU from "../assets/TAICHU.svg";
import { UserContext } from "../UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import { DropdownMenu, DropdownItemButton } from "./Dropdown";
import cog from "../assets/bxs-cog.svg";
import logout from "../assets/bx-log-out.svg";

const Nav = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${(props) => props.theme.colors.primary};
    padding: 8px;
    margin-bottom: 10px;
`;

const NavLeft = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
    flex: 1;
    padding-left: 6px;

    &:hover > img {
        filter: invert(14%) sepia(72%) saturate(451%) hue-rotate(333deg) brightness(96%) contrast(110%);
    }

    &:active > img {
        filter: none;
    }
`;

const NavRight = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 16px;
    flex: 1;
    padding-right: 6px;
`;

const NavBarButton = styled.button`
    background-color: ${(props) => props.theme.colors.primary_dark};
    border: none;
    color: white;
    padding: 8px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;

    &:hover {
        background-color: ${(props) => props.theme.colors.primary_darker};
    }
`;

export function NavBar(): JSX.Element {
    const userContext = React.useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Nav>
            <NavLeft onClick={() => navigate("/")}>
                <img src={butterfly} alt="butterfly" height="50px" />
                <img src={TAICHU} alt="TAICHU" height="40px" />
            </NavLeft>
            <NavRight>
                {/* <RunSimButton onClick={() => apiClient.runSim()}>Run Sim</RunSimButton> */}
                <NavBarButton onClick={() => navigate("/about")}>About</NavBarButton>
                {userContext ? (
                    <DropdownMenu
                        triggerButton={<NavBarButton onClick={() => navigate("/settings")}>{userContext.user.display_name}</NavBarButton>}
                    >
                        {location.pathname !== "/settings" && (
                            <DropdownItemButton onClick={() => navigate("/settings")}>
                                <img src={cog} alt="settings-cog" height="16px" style={{ marginRight: "6px", marginBottom: "-3px" }} />
                                Settings
                            </DropdownItemButton>
                        )}
                        <DropdownItemButton
                            onClick={() => {
                                userContext.logoutFunc();
                                navigate("/");
                            }}
                        >
                            <img
                                src={logout}
                                alt="log-out"
                                height="16px"
                                style={{ marginRight: "6px", marginBottom: "-3px", marginLeft: "-2px" }}
                            />
                            Log Out
                        </DropdownItemButton>
                    </DropdownMenu>
                ) : null}
            </NavRight>
        </Nav>
    );
}
