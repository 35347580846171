import React from "react";
import { DmTableParticipant, DmPosition } from "../../client/server-types-python";
import styled from "styled-components";
import { getName } from "../../client/util";

export enum Alignment {
    BOTTOM = "bottom",
    LEFT = "left",
    TOP = "top",
    RIGHT = "right",
}

const SeatDiv = styled.div<{ $alignment: Alignment }>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0px solid black;
    margin: 12px;
    border-radius: 8px;
    font-size: 36px;
    color: white;
    ${(props) => {
        switch (props.$alignment) {
            case Alignment.BOTTOM:
                return "width: 514px; height: 122px;";
            case Alignment.LEFT:
                return "height: 514px; width: 122px; flex-direction: row-reverse;";
            case Alignment.TOP:
                return "width: 514px; height: 122px;";
            case Alignment.RIGHT:
                return "height: 514px; width: 122px; flex-direction: row;";
        }
    }}
`;

const PlayerInfoWrapper = styled.div<{ $alignment: Alignment }>`
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Ensure content stays within bounds */
    ${(props) => {
        switch (props.$alignment) {
            case Alignment.BOTTOM:
            case Alignment.TOP:
                return "width: 100%; height: 60px;";
            case Alignment.LEFT:
            case Alignment.RIGHT:
                return "height: 100%; width: 60px;";
        }
    }}
`;

const PlayerInfoDiv = styled.div<{ $active: boolean; $alignment: Alignment }>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 0px solid black;
    margin: 12px;
    border-radius: 8px;
    font-size: 36px;
    color: white;
    background-color: rgb(158, 207, 230);
    padding: 0px;

    // add a glow around it if active
    ${(props) => (props.$active ? "box-shadow: 0 0 8px 5px #ffd856;" : "")}
    ${(props) => {
        switch (props.$alignment) {
            case Alignment.BOTTOM:
                return "";
            case Alignment.LEFT:
                return "transform: rotate(90deg);";
            case Alignment.TOP:
                return "";
            case Alignment.RIGHT:
                return "transform: rotate(-90deg);";
        }
    }}
`;

const PlayerInfoLabel = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 0px solid black;
    margin: 2px 10px 2px 10px;
    font-size: 20px;
    color: black;
    white-space: nowrap;
`;

const LimboCardsDiv = styled.div`
    position: absolute;
    top: -89px;
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 12px;
    font-size: 36px;
    color: white;
`;

export class Seat extends React.Component<{
    player: DmTableParticipant | null;
    position: DmPosition;
    active: boolean;
    alignment: Alignment;
    bet: React.ReactNode;
    hand: React.ReactNode;
    limboCards?: React.ReactNode;
}> {
    render() {
        const { player, active, alignment, bet, hand, limboCards } = this.props;
        return (
            <SeatDiv $alignment={alignment}>
                {limboCards && <LimboCardsDiv>{limboCards}</LimboCardsDiv>}
                {alignment === Alignment.TOP && hand}
                <PlayerInfoWrapper $alignment={alignment}>
                    <PlayerInfoDiv $active={active} $alignment={alignment}>
                        {bet}
                        <PlayerInfoLabel>{getName(player)}</PlayerInfoLabel>
                    </PlayerInfoDiv>
                </PlayerInfoWrapper>
                {alignment !== Alignment.TOP && hand}
            </SeatDiv>
        );
    }
}
