import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ApiClient } from "./client/ApiClient";
import { Login } from "./components/Login";
import { WebSocketClient } from "./client/WebSocketClient";
import { UserContext } from "./UserContext";
import { NavBar } from "./components/NavBar";
import {
    DmAuthResponse,
    DmGame,
    DmGameEvent,
    DmGameEventType,
    DmLobbyEvent,
    DmLobbyEventType,
    DmTable,
    DmTableEvent,
    DmUser,
} from "./client/server-types-python";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import { TablesPage } from "./pages/TablesPage";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ResetPasswordPage } from "./pages/ResetPasswordPage";
import { UserSettingsPage } from "./pages/UserSettingsPage";
import { setVolume } from "./misc/SoundPlayer";
import { AboutPage } from "./pages/AboutPage";

function App() {
    const base_url = process.env.REACT_APP_API_BASE_URL;
    if (base_url === undefined) {
        throw new Error("no REACT_APP_API_BASE_URL");
    }
    const apiClient = useMemo(() => new ApiClient(base_url), [base_url]);
    const localStorageUserId = localStorage.getItem("userId");
    const localStorageUserEmail = localStorage.getItem("userEmail");
    const localStorageUserDisplayName = localStorage.getItem("userDisplayName");
    const [user, setUser] = useState<DmUser | null>(
        localStorageUserId && localStorageUserEmail
            ? { id: localStorageUserId, email: localStorageUserEmail, display_name: localStorageUserDisplayName ?? "" }
            : null
    );
    const [verifyingStoredUser, setVerifyingStoredUser] = useState(!!(localStorageUserId && localStorageUserEmail));
    const [tablesState, setTablesState] = useState<DmTable[]>([]);
    const [tableId, setTableId] = useState<string | null>(null);
    // TODO: should we do something more with this state? It's a timestamp - if it takes too long we could try again.
    const [initialLoadInProgressSince, setInitialLoadInProgressSince] = useState<number | null>(Date.now());

    const game = tablesState.find((table) => table.id === tableId)?.game ?? null;

    const setGame = useCallback(
        (game: DmGame) => {
            setTablesState((prevTablesState) => prevTablesState.map((table) => (table.id === tableId ? { ...table, game: game } : table)));
        },
        [tableId, setTablesState]
    );

    const [webSocketClient, setWebSocketClient] = useState<WebSocketClient | null>(null);

    const tableIdRef = useRef(tableId);
    tableIdRef.current = tableId;
    const onTableEvent = useCallback(
        (event: DmTableEvent | DmGameEvent) => {
            if (event.type === DmGameEventType.GAME_MOVE) {
                if (tableId === null) {
                    return;
                }
                if (game === null) {
                    apiClient.getTable(tableId).then((table) => {
                        if (table instanceof Error) {
                            console.error(table);
                            return;
                        }
                        setGame(table.game);
                        console.log("table.game", table.game);
                    });
                } else {
                    const latestHand = game.game_hands[game.game_hands.length - 1];
                    if (latestHand.history === null || latestHand.history[latestHand.history.length - 1].index !== event.entry.index - 1) {
                        apiClient.getGameHand(tableId, latestHand.index).then((gameHand) => {
                            if (gameHand instanceof Error) {
                                console.error(gameHand);
                                return;
                            }
                            const newGame = {
                                ...game,
                                game_hands: game.game_hands.map((hand) => (hand.index === gameHand.index ? gameHand : hand)),
                            };
                            setGame(newGame);
                        });
                    } else {
                        const newHistory = [...latestHand.history, event.entry];
                        const newHand = { ...latestHand, history: newHistory, play_state: event.entry.state };
                        const newGame = {
                            ...game,
                            game_hands: game.game_hands.map((hand) => (hand.index === latestHand.index ? newHand : hand)),
                        };
                        setGame(newGame);
                    }
                }
            } else {
                setGame(event.game);
                // TODO: distinguish between different table events
            }
        },
        [apiClient, game, setGame, tableId]
    );

    // Verify the stored user from local storage. If this fails, clear the stored user.
    useEffect(() => {
        if (verifyingStoredUser) {
            const response = apiClient.verifyToken();
            response.then((response) => {
                if (response instanceof Error) {
                    localStorage.removeItem("userId");
                    localStorage.removeItem("userEmail");
                    localStorage.removeItem("userDisplayName");
                    localStorage.removeItem("jwt");
                    setUser(null);
                } else {
                    setUser(response);
                }
                setVerifyingStoredUser(false);
            });
        }
    }, [user, verifyingStoredUser, apiClient]);

    // Once we have a verified user, set up the websocket client
    useEffect(() => {
        const jwt = localStorage.getItem("jwt");
        if (verifyingStoredUser || user === null || jwt === null) {
            setWebSocketClient(null);
            return;
        }

        const onConnect = () => {
            // fetch lobby info
            apiClient.listTables().then((tables) => {
                if (tables instanceof Error) {
                    console.error(tables);
                    return;
                }
                setInitialLoadInProgressSince(null);
                setTablesState(tables);
                const currentTable = user && tables.find((table) => table.players.some((player) => player?.user?.id === user.id));
                if (currentTable) {
                    setTableId(currentTable.id);
                    if (currentTable.game) {
                        apiClient.getTable(currentTable.id).then((table) => {
                            if (table instanceof Error) {
                                console.error(table);
                                return;
                            }
                            setGame(table.game);
                            console.log("table.game", table.game);
                        });
                    }
                }
            });
        };

        const onLobbyEvent = (event: DmLobbyEvent) => {
            if (tableIdRef.current === event.table.id) {
                // If the event updates the current table such that we are no longer at it, unset table id
                if (!event.table.players.some((player) => player?.user?.id === user?.id)) {
                    setTableId(null);
                }
            }
            if (event.type === DmLobbyEventType.TABLE_CREATED) {
                setTablesState((prevTablesState) => prevTablesState.concat(event.table));
            } else if (event.type === DmLobbyEventType.TABLE_CLOSED) {
                setTablesState((prevTablesState) => prevTablesState.filter((table) => table.id !== event.table.id));
            } else if (event.type === DmLobbyEventType.TABLE_UPDATED) {
                setTablesState((prevTablesState) => prevTablesState.map((table) => (table.id === event.table.id ? event.table : table)));
            }
            if (event.type === DmLobbyEventType.TABLE_CREATED || event.type === DmLobbyEventType.TABLE_UPDATED) {
                // If the event creates or updates a table such that we are now at it, set table id
                if (event.table.players.some((player) => player?.user?.id === user?.id)) {
                    setTableId(event.table.id);
                }
            }
        };

        const client = new WebSocketClient(base_url, jwt, onLobbyEvent, onConnect);

        setWebSocketClient(client);

        // Clean up function
        return () => {
            client.disconnect();
        };
    }, [user, verifyingStoredUser, apiClient, setGame, base_url]);

    // While we have a websocket client and are at a table, subscribe to table events
    useEffect(() => {
        if (webSocketClient !== null) {
            if (tableId) {
                webSocketClient.subscribeToTableEvents(tableId, onTableEvent);
            } else {
                webSocketClient.unsubscribeFromTableEvents();
            }
        }
    }, [tableId, webSocketClient, onTableEvent]);

    // Set volume based on user setting
    useEffect(() => {
        if (user === null || user.user_settings?.volume === undefined) {
            return;
        }
        const volume = user.user_settings.volume;
        setVolume(volume / 4);
    }, [user]);

    const createTable = () => {
        const response = apiClient.newTable();
        response.then((responseTable) => {
            if (responseTable instanceof Error) {
                return undefined;
            }
            setTablesState(tablesState.concat(responseTable));
            setTableId(responseTable.id);
        });
    };

    const joinTable = (tableId: string) => {
        apiClient.joinTable(tableId);
        // lobby event should follow soon and move us to the table
    };

    const setLoggedInCallback = (dmAuthResponse: DmAuthResponse): void => {
        localStorage.setItem("userDisplayName", dmAuthResponse.user.display_name);
        localStorage.setItem("userId", `${dmAuthResponse.user.id}`);
        localStorage.setItem("userEmail", dmAuthResponse.user.email);
        localStorage.setItem("jwt", dmAuthResponse.access_token);
        setUser(dmAuthResponse.user);
    };

    const updateUser = (user: DmUser): void => {
        localStorage.setItem("userDisplayName", user.display_name);
        setUser(user);
    };

    const logOut = () => {
        localStorage.removeItem("userId");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("userDisplayName");
        localStorage.removeItem("jwt");
        setUser(null);
    };

    return (
        <ThemeProvider theme={theme}>
            <UserContext.Provider value={user ? { user, logoutFunc: logOut } : null}>
                <BrowserRouter>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                user !== null ? (
                                    <>
                                        <TablesPage
                                            initialLoadInProgressSince={initialLoadInProgressSince}
                                            tableId={tableId}
                                            tablesState={tablesState}
                                            apiClient={apiClient}
                                            joinTable={joinTable}
                                            createTable={createTable}
                                            webSocketClient={webSocketClient}
                                            game={game}
                                        ></TablesPage>
                                    </>
                                ) : (
                                    <>
                                        <NavBar />
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                width: "100%",
                                                height: "300px",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                alignContent: "center",
                                            }}
                                        >
                                            <Login
                                                apiClient={apiClient}
                                                setLoggedInCallback={setLoggedInCallback}
                                                resetPasswordPageData={null}
                                            ></Login>
                                        </div>
                                    </>
                                )
                            }
                        ></Route>
                        <Route
                            path="/new_password"
                            element={
                                <ResetPasswordPage apiClient={apiClient} setLoggedInCallback={setLoggedInCallback}></ResetPasswordPage>
                            }
                        ></Route>
                        <Route
                            path="/settings"
                            element={
                                user ? (
                                    <UserSettingsPage apiClient={apiClient} user={user} updateUser={updateUser}></UserSettingsPage>
                                ) : (
                                    <Navigate to="/" replace />
                                )
                            }
                        ></Route>
                        <Route path="/about" element={<AboutPage />}></Route>
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </BrowserRouter>
            </UserContext.Provider>
        </ThemeProvider>
    );
}

export default App;
