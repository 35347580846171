import { CardId } from "../client/basic-types";
import { CombinationType, DmCombination } from "../client/server-types-python";
import { CardDetails, Suit } from "./CardDetails";
import { MoveUtil } from "./MoveUtil";

/**
 * Provides convenience to players by suggesting legal moves using their selected cards, to
 * be displayed as one-click options.
 */
export class MoveAssistant {
    public static suggestMoves(
        selectedCardIds: CardId[],
        handCardIds: CardId[],
        cardIdsKnownByOpps: CardId[],
        cardIdsKnownByPartner: CardId[],
        winningCombination?: DmCombination,
        wish?: number
    ): DmCombination[] {
        if (selectedCardIds.length === 0) {
            // Don't suggest with nothing selected. TODO: Maybe we can still suggest?
            return [];
        }
        const selectedCards = selectedCardIds.map((id) => CardDetails.getCardDetails(id));
        const handCards = handCardIds.map((id) => CardDetails.getCardDetails(id));

        const result: DmCombination[] = [];

        const mustFulfillWish = wish !== undefined && MoveUtil.canFulfillWish(handCards, winningCombination, wish);
        const requiredWishRank = mustFulfillWish ? wish : undefined; // Only set if the player can fulfill the wish!

        result.push(
            ...this._getPairSuggestion(
                selectedCards,
                handCards,
                cardIdsKnownByOpps,
                cardIdsKnownByPartner,
                winningCombination,
                requiredWishRank
            )
        );

        result.push(...this._getTripleSuggestion(selectedCards, handCards, winningCombination, requiredWishRank));

        result.push(
            ...this._getFullHouseSuggestion(
                selectedCards,
                handCards,
                cardIdsKnownByOpps,
                cardIdsKnownByPartner,
                winningCombination,
                requiredWishRank
            )
        );

        result.push(
            ...this._getStairSuggestions(
                selectedCards,
                handCards,
                cardIdsKnownByOpps,
                cardIdsKnownByPartner,
                winningCombination,
                requiredWishRank
            )
        );

        result.push(
            ...this._getStraightSuggestions(
                selectedCards,
                handCards,
                cardIdsKnownByOpps,
                cardIdsKnownByPartner,
                winningCombination,
                requiredWishRank
            )
        );

        result.push(...this._getBombSuggestions(selectedCards, handCards, winningCombination, requiredWishRank));

        result.sort((a, b) => {
            const aBomb = a.combination_type === CombinationType.STRAIGHT_FLUSH_BOMB || a.combination_type === CombinationType.QUAD_BOMB;
            const bBomb = b.combination_type === CombinationType.STRAIGHT_FLUSH_BOMB || b.combination_type === CombinationType.QUAD_BOMB;
            if (aBomb && !bBomb) {
                return 1;
            } else if (!aBomb && bBomb) {
                return -1;
            } else {
                return 100 * (a.cards.length - b.cards.length) + a.rank - b.rank;
            }
        });

        if (result.length > 2) {
            return [result[0], result[result.length - 1]];
        }

        return result;
    }

    /**
     * Picks the best pair using the single selected rank card. Priority:
     * 1) Don't pair if the player has a quad bomb
     * 2) Pair with a normal card, not the phoenix
     * 3) Pair with an opponent-known card, not with a partner-known card
     */
    public static _getPairSuggestion(
        selectedCards: CardDetails[],
        handCards: CardDetails[],
        cardIdsKnownByOpps: CardId[],
        cardIdsKnownByPartner: CardId[],
        winningCombination?: DmCombination,
        wish?: number
    ): DmCombination[] {
        if (selectedCards.length !== 1) {
            // Don't suggest unless only one card selected.
            return [];
        }
        if (selectedCards[0].suit === Suit.UNKNOWN) {
            // Can't pair with special cards.
            return [];
        }
        if (
            winningCombination !== undefined &&
            (winningCombination.combination_type !== CombinationType.PAIR || winningCombination.rank >= selectedCards[0].rank)
        ) {
            // Can't beat the winning combination.
            return [];
        }
        if (wish !== undefined && selectedCards[0].rank !== wish) {
            return [];
        }
        const result: DmCombination[] = [];
        const otherCardsOfRank = handCards.filter((card) => card.rank === selectedCards[0].rank && card.suit !== selectedCards[0].suit);
        if (otherCardsOfRank.length === 1) {
            result.push({
                combination_type: CombinationType.PAIR,
                cards: [selectedCards[0].cardId, otherCardsOfRank[0].cardId],
                rank: selectedCards[0].rank,
            });
        } else if (otherCardsOfRank.length === 2) {
            if (
                (cardIdsKnownByPartner.length === 1 && otherCardsOfRank[0].cardId === cardIdsKnownByPartner[0]) ||
                cardIdsKnownByOpps.some((id) => id === otherCardsOfRank[1].cardId)
            ) {
                result.push({
                    combination_type: CombinationType.PAIR,
                    cards: [selectedCards[0].cardId, otherCardsOfRank[1].cardId],
                    rank: selectedCards[0].rank,
                });
            } else {
                result.push({
                    combination_type: CombinationType.PAIR,
                    cards: [selectedCards[0].cardId, otherCardsOfRank[0].cardId],
                    rank: selectedCards[0].rank,
                });
            }
        } else if (otherCardsOfRank.length === 0) {
            // No other cards of rank. Suggest a pair with the phoenix if available
            const hasPhoenix = handCards.some((card) => card.cardId === CardId.PHOENIX);
            if (hasPhoenix) {
                result.push({
                    combination_type: CombinationType.PAIR,
                    cards: [selectedCards[0].cardId, CardId.PHOENIX],
                    rank: selectedCards[0].rank,
                });
            }
        }
        // With 3 other cards of rank (i.e. a bomb), don't suggest a pair
        return result;
    }

    public static _getTripleSuggestion(
        selectedCards: CardDetails[],
        handCards: CardDetails[],
        winningCombination?: DmCombination,
        wish?: number
    ): DmCombination[] {
        if (selectedCards.length === 0 || selectedCards.length > 2) {
            // Don't suggest with nothing selected, or with 3+ selected
            return [];
        }
        if (selectedCards.length === 2) {
            if (selectedCards[0].rank !== selectedCards[1].rank || selectedCards[1].suit === Suit.UNKNOWN) {
                // Don't suggest with two different ranks selected, or if second card is special
                return [];
            }
        }
        if (selectedCards[0].suit === Suit.UNKNOWN) {
            // Can't triple with special cards.
            return [];
        }
        if (
            winningCombination !== undefined &&
            (winningCombination.combination_type !== CombinationType.TRIPLE || winningCombination.rank >= selectedCards[0].rank)
        ) {
            // Can't beat the winning combination.
            return [];
        }
        if (wish !== undefined && selectedCards[0].rank !== wish) {
            return [];
        }
        const result: DmCombination[] = [];
        const cardsOfRank = handCards.filter((card) => card.rank === selectedCards[0].rank);
        if (cardsOfRank.length === 3) {
            result.push({
                combination_type: CombinationType.TRIPLE,
                cards: cardsOfRank.map((card) => card.cardId),
                rank: selectedCards[0].rank,
            });
        }
        return result;
    }

    public static _getFullHouseSuggestion(
        selectedCards: CardDetails[],
        handCards: CardDetails[],
        cardIdsKnownByOpps: CardId[],
        cardIdsKnownByPartner: CardId[],
        winningCombination?: DmCombination,
        wish?: number
    ): DmCombination[] {
        if (selectedCards.length === 0 || selectedCards.length >= 5) {
            // Don't suggest with nothing selected or with the whole FH selected
            return [];
        }
        const requiredRanks = new Set<number>();
        for (const card of selectedCards) {
            if (card.suit === Suit.UNKNOWN) {
                return [];
            }
            requiredRanks.add(card.rank);
        }
        if (wish !== undefined) {
            requiredRanks.add(wish);
        }
        if (requiredRanks.size > 2) {
            // Don't suggest with more than 2 different ranks selected / wished for
            return [];
        }
        if (winningCombination !== undefined && winningCombination.combination_type !== CombinationType.FULL_HOUSE) {
            // Wrong combination type
            return [];
        }
        const result: DmCombination[] = [];
        if (requiredRanks.size === 2) {
            const requiredRanksArray = Array.from(requiredRanks).sort();
            const cardsOfRankA = handCards.filter((card) => card.rank === requiredRanksArray[0]);
            const cardsOfRankB = handCards.filter((card) => card.rank === requiredRanksArray[1]);
            const existsTripleA = cardsOfRankA.length >= 3;
            const existsTripleB = cardsOfRankB.length >= 3;
            const existsPairA = cardsOfRankA.length >= 2;
            const existsPairB = cardsOfRankB.length >= 2;
            if (existsTripleA && existsPairB && (!winningCombination || winningCombination.rank < requiredRanksArray[0])) {
                const selectedCardsOfRankA = selectedCards.filter((card) => card.rank === requiredRanksArray[0]);
                const selectedCardsOfRankB = selectedCards.filter((card) => card.rank === requiredRanksArray[1]);
                if (selectedCardsOfRankA.length <= 3 && selectedCardsOfRankB.length <= 2) {
                    result.push(this._formFullHouse(cardsOfRankA, cardsOfRankB, selectedCards, cardIdsKnownByOpps, cardIdsKnownByPartner));
                }
            }
            if (existsTripleB && existsPairA && (!winningCombination || winningCombination.rank < requiredRanksArray[1])) {
                const selectedCardsOfRankA = selectedCards.filter((card) => card.rank === requiredRanksArray[0]);
                const selectedCardsOfRankB = selectedCards.filter((card) => card.rank === requiredRanksArray[1]);
                if (selectedCardsOfRankA.length <= 2 && selectedCardsOfRankB.length <= 3) {
                    result.push(this._formFullHouse(cardsOfRankB, cardsOfRankA, selectedCards, cardIdsKnownByOpps, cardIdsKnownByPartner));
                }
            }
        } else {
            // 1 required rank
            const requiredRank = requiredRanks.values().next().value;
            const cardsOfRank = handCards.filter((card) => card.rank === requiredRank);
            const existsTripleOfRank = cardsOfRank.length >= 3;
            const existsPairOfRank = cardsOfRank.length >= 2;
            if (!existsPairOfRank) {
                return result;
            }
            const sortedNonSpecialHandCards = handCards
                .filter((card) => card.suit !== Suit.UNKNOWN)
                .sort((a, b) => a.sortOrder - b.sortOrder);
            if (!existsTripleOfRank || (winningCombination && winningCombination.rank >= requiredRank)) {
                // Look for supplementary triples
                const triples: CardDetails[][] = [];
                let rank = -1;
                let count = 0;
                let i = 0;
                for (; i < sortedNonSpecialHandCards.length; i++) {
                    if (winningCombination && sortedNonSpecialHandCards[i].rank <= winningCombination.rank) {
                        continue;
                    }
                    if (sortedNonSpecialHandCards[i].rank === rank) {
                        count++;
                    } else {
                        if (count >= 3) {
                            triples.push(sortedNonSpecialHandCards.slice(i - count, i));
                        }
                        rank = sortedNonSpecialHandCards[i].rank;
                        count = 1;
                    }
                }
                if (count >= 3) {
                    triples.push(sortedNonSpecialHandCards.slice(i - count, i));
                }
                if (triples.length >= 1) {
                    const bestTriple = triples[triples.length - 1];
                    result.push(this._formFullHouse(bestTriple, cardsOfRank, selectedCards, cardIdsKnownByOpps, cardIdsKnownByPartner));
                }
                if (triples.length >= 2) {
                    const worstTriple = triples[0];
                    result.push(this._formFullHouse(worstTriple, cardsOfRank, selectedCards, cardIdsKnownByOpps, cardIdsKnownByPartner));
                }
            } else {
                // Look for supplementary pairs
                const pairs: CardDetails[][] = [];
                const triples: CardDetails[][] = [];
                const quads: CardDetails[][] = [];
                let rank = -1;
                let count = 0;
                let i = 0;
                for (; i < sortedNonSpecialHandCards.length; i++) {
                    if (winningCombination && sortedNonSpecialHandCards[i].rank <= winningCombination.rank) {
                        continue;
                    }
                    if (sortedNonSpecialHandCards[i].rank === rank) {
                        count++;
                    } else {
                        if (count >= 2 && rank !== requiredRank) {
                            pairs.push(sortedNonSpecialHandCards.slice(i - count, i));
                        }
                        rank = sortedNonSpecialHandCards[i].rank;
                        count = 1;
                    }
                }
                if (count >= 2 && rank !== requiredRank) {
                    if (count === 2) {
                        pairs.push(sortedNonSpecialHandCards.slice(i - count, i));
                    } else if (count === 3) {
                        triples.push(sortedNonSpecialHandCards.slice(i - count, i));
                    } else {
                        quads.push(sortedNonSpecialHandCards.slice(i - count, i));
                    }
                }
                if (pairs.length >= 1) {
                    const bestPair = pairs[0];
                    result.push(this._formFullHouse(cardsOfRank, bestPair, selectedCards, cardIdsKnownByOpps, cardIdsKnownByPartner));
                } else if (triples.length >= 1) {
                    const bestPair = triples[0];
                    result.push(this._formFullHouse(cardsOfRank, bestPair, selectedCards, cardIdsKnownByOpps, cardIdsKnownByPartner));
                } else if (quads.length >= 1) {
                    const bestPair = quads[0];
                    result.push(this._formFullHouse(cardsOfRank, bestPair, selectedCards, cardIdsKnownByOpps, cardIdsKnownByPartner));
                }
            }
        }
        return result;
    }

    public static _formFullHouse(
        potentialTripCards: CardDetails[],
        potentialPairCards: CardDetails[],
        selectedCards: CardDetails[],
        cardIdsKnownByOpps: CardId[],
        cardIdsKnownByPartner: CardId[]
    ): DmCombination {
        if (potentialPairCards.length > 2) {
            potentialPairCards = [...potentialPairCards];
            potentialPairCards.sort((a, b) => {
                if (selectedCards.includes(a)) {
                    return -1;
                }
                if (selectedCards.includes(b)) {
                    return 1;
                }
                if (cardIdsKnownByOpps.includes(a.cardId)) {
                    return -1;
                }
                if (cardIdsKnownByOpps.includes(b.cardId)) {
                    return 1;
                }
                if (cardIdsKnownByPartner.includes(a.cardId)) {
                    return 1;
                }
                if (cardIdsKnownByPartner.includes(b.cardId)) {
                    return -1;
                }
                return 0;
            });
        }
        if (potentialTripCards.length > 3) {
            potentialTripCards = [...potentialTripCards];
            potentialTripCards.sort((a, b) => {
                if (selectedCards.includes(a)) {
                    return -1;
                }
                if (selectedCards.includes(b)) {
                    return 1;
                }
                if (cardIdsKnownByOpps.includes(a.cardId)) {
                    return -1;
                }
                if (cardIdsKnownByOpps.includes(b.cardId)) {
                    return 1;
                }
                if (cardIdsKnownByPartner.includes(a.cardId)) {
                    return 1;
                }
                if (cardIdsKnownByPartner.includes(b.cardId)) {
                    return -1;
                }
                return 0;
            });
        }
        return {
            combination_type: CombinationType.FULL_HOUSE,
            cards: potentialTripCards
                .slice(0, 3)
                .concat(potentialPairCards.slice(0, 2))
                .map((card) => card.cardId),
            rank: potentialTripCards[0].rank,
        };
    }

    public static _getStairSuggestions(
        selectedCards: CardDetails[],
        handCards: CardDetails[],
        cardIdsKnownByOpps: CardId[],
        cardIdsKnownByPartner: CardId[],
        winningCombination?: DmCombination,
        wish?: number
    ): DmCombination[] {
        if (selectedCards.length === 0) {
            // Don't suggest with nothing selected. TODO: Maybe we can still suggest?
            return [];
        }
        if (
            winningCombination !== undefined &&
            (winningCombination.combination_type !== CombinationType.STAIRS || winningCombination.cards.length <= selectedCards.length)
        ) {
            // Can't beat the winning combination, or there is no room to add cards to selected in beating it.
            return [];
        }
        const winningStairLength = winningCombination ? winningCombination.cards.length / 2 : 0;
        const result: DmCombination[] = [];
        // 1. Find the best 2 cards of each rank. This definitely includes selected cards.
        const bestCardsByRank: (CardDetails | null)[] = new Array(13).fill(null);
        const bestCardsByRankScores = new Array(13).fill(0);
        const secondBestCardsByRank: (CardDetails | null)[] = new Array(13).fill(null);
        const secondBestCardsByRankScores = new Array(13).fill(0);
        let lowestRequiredRank = wish === undefined ? 15 : wish;
        let highestRequiredRank = wish === undefined ? 0 : wish;
        if (winningCombination !== undefined) {
            highestRequiredRank = Math.max(highestRequiredRank, winningCombination.rank + 1);
        }
        for (const card of selectedCards) {
            if (card.suit === Suit.UNKNOWN) {
                return [];
            }
            if (bestCardsByRank[card.rank - 2] === null) {
                bestCardsByRank[card.rank - 2] = card;
                bestCardsByRankScores[card.rank - 2] = 100;
            } else {
                if (secondBestCardsByRank[card.rank - 2] !== null) {
                    // triplicate rank
                    return [];
                }
                secondBestCardsByRank[card.rank - 2] = card;
                secondBestCardsByRankScores[card.rank - 2] = 100;
            }
            lowestRequiredRank = Math.min(lowestRequiredRank, card.rank);
            highestRequiredRank = Math.max(highestRequiredRank, card.rank);
        }
        const selectedCardsSet = new Set(selectedCards);
        handCards = handCards.filter((card) => !selectedCardsSet.has(card));
        for (const card of handCards) {
            if (card.suit !== Suit.UNKNOWN && secondBestCardsByRankScores[card.rank - 2] !== 100) {
                const score = cardIdsKnownByOpps.includes(card.cardId) ? 30 : cardIdsKnownByPartner.includes(card.cardId) ? 10 : 20;
                if (score > bestCardsByRankScores[card.rank - 2]) {
                    secondBestCardsByRank[card.rank - 2] = bestCardsByRank[card.rank - 2];
                    secondBestCardsByRankScores[card.rank - 2] = bestCardsByRankScores[card.rank - 2];
                    bestCardsByRank[card.rank - 2] = card;
                    bestCardsByRankScores[card.rank - 2] = score;
                } else if (score > secondBestCardsByRankScores[card.rank - 2]) {
                    secondBestCardsByRank[card.rank - 2] = card;
                    secondBestCardsByRankScores[card.rank - 2] = score;
                } else {
                    bestCardsByRankScores[card.rank - 2] += 1; // Mark that we passed up the opportunity to sub a card
                }
            }
        }
        // 2. Find the longest stair that spans the required ranks
        const lowestAllowedRank = winningCombination ? winningCombination.rank - winningStairLength + 2 : 2;
        let lowRank = lowestRequiredRank;
        if (secondBestCardsByRank[lowRank - 2] === null) {
            // Can't start a stair from a missing rank
            return [];
        }
        let highRank = lowRank;
        while (highRank < 14 && secondBestCardsByRank[highRank - 2 + 1] !== null) {
            highRank++;
        }
        if (highRank < highestRequiredRank) {
            // Could not connect the highest required rank to the lowest one
            return [];
        }
        while (lowRank > lowestAllowedRank && secondBestCardsByRank[lowRank - 2 - 1] !== null) {
            lowRank--;
        }
        const minLength = winningCombination ? winningStairLength : Math.max(2, Math.ceil((selectedCards.length + 1) / 2));
        if (highRank - lowRank + 1 < minLength) {
            // Not long enough
            return [];
        }
        if (winningCombination === undefined) {
            const longStairCards1 = bestCardsByRank.slice(lowRank - 2, highRank - 2 + 1);
            const longStairCards2 = secondBestCardsByRank.slice(lowRank - 2, highRank - 2 + 1);
            const allLongStairCards = longStairCards1.concat(longStairCards2).sort((a, b) => a!.sortOrder - b!.sortOrder);
            result.push({
                combination_type: CombinationType.STAIRS,
                cards: allLongStairCards.map((card) => card!.cardId),
                rank: highRank,
            });
            if (highRank - lowRank + 1 > minLength) {
                // Create the weakest, smallest stair we can
                const h = Math.max(highestRequiredRank, lowRank + minLength - 1);
                const l = Math.min(lowestRequiredRank, h - minLength + 1);
                const shortStairCards1 = bestCardsByRank.slice(l - 2, h - 2 + 1);
                const shortStairCards2 = secondBestCardsByRank.slice(l - 2, h - 2 + 1);
                const allShortStairCards = shortStairCards1.concat(shortStairCards2).sort((a, b) => a!.sortOrder - b!.sortOrder);
                if (highRank !== h || lowRank !== l) {
                    // make sure it's not the same stair again...
                    result.push({
                        combination_type: CombinationType.STAIRS,
                        cards: allShortStairCards.map((card) => card!.cardId),
                        rank: h,
                    });
                }
            }
        } else {
            // We only care about stairs of matching length.
            // First: the strongest one
            let bigH = highRank;
            let bigL = lowRank;
            while (bigH - bigL + 1 > minLength && bigL < lowestRequiredRank) {
                bigL++;
            }
            while (bigH - bigL + 1 > minLength && bigH > highestRequiredRank) {
                bigH--;
            }
            if (bigH - bigL + 1 === minLength) {
                const longStairCards1 = bestCardsByRank.slice(bigL - 2, bigH - 2 + 1);
                const longStairCards2 = secondBestCardsByRank.slice(bigL - 2, bigH - 2 + 1);
                const allLongStairCards = longStairCards1.concat(longStairCards2).sort((a, b) => a!.sortOrder - b!.sortOrder);
                result.push({
                    combination_type: CombinationType.STAIRS,
                    cards: allLongStairCards.map((card) => card!.cardId),
                    rank: bigH,
                });
            }
            // Then: the weakest one
            let smallH = highRank;
            let smallL = lowRank;
            while (smallH - smallL + 1 > minLength && smallH > highestRequiredRank) {
                smallH--;
            }
            while (smallH - smallL + 1 > minLength && smallL < lowestRequiredRank) {
                smallL++;
            }
            if (smallH - smallL + 1 === minLength && !(smallH === bigH && smallL === bigL)) {
                const longStairCards1 = bestCardsByRank.slice(smallL - 2, smallH - 2 + 1);
                const longStairCards2 = secondBestCardsByRank.slice(smallL - 2, smallH - 2 + 1);
                const allLongStairCards = longStairCards1.concat(longStairCards2).sort((a, b) => a!.sortOrder - b!.sortOrder);
                result.push({
                    combination_type: CombinationType.STAIRS,
                    cards: allLongStairCards.map((card) => card!.cardId),
                    rank: smallH,
                });
            }
        }

        return result;
    }

    public static _getStraightSuggestions(
        selectedCards: CardDetails[],
        handCards: CardDetails[],
        cardIdsKnownByOpps: CardId[],
        cardIdsKnownByPartner: CardId[],
        winningCombination?: DmCombination,
        wish?: number
    ): DmCombination[] {
        if (selectedCards.length === 0) {
            // Don't suggest with nothing selected. TODO: Maybe we can still suggest?
            return [];
        }
        if (winningCombination !== undefined && winningCombination.combination_type !== CombinationType.STRAIGHT) {
            // Can't beat the winning combination.
            return [];
        }
        const result: DmCombination[] = [];
        // 1. Find the best card of each rank. This definitely includes selected cards.
        const bestCardsByRank: (CardDetails | null)[] = new Array(14).fill(null);
        const bestCardsByRankScores = new Array(14).fill(0);
        let lowestRequiredRank = wish === undefined ? 15 : wish;
        let highestRequiredRank = wish === undefined ? 0 : wish;
        if (winningCombination !== undefined) {
            highestRequiredRank = Math.max(highestRequiredRank, winningCombination.rank + 1);
        }
        for (const card of selectedCards) {
            if (card.suit === Suit.UNKNOWN && card.cardId !== CardId.MAHJONG) {
                return [];
            }
            if (bestCardsByRank[card.rank - 1] !== null) {
                // duplicate rank
                return [];
            }
            bestCardsByRank[card.rank - 1] = card;
            bestCardsByRankScores[card.rank - 1] = 100;
            lowestRequiredRank = Math.min(lowestRequiredRank, card.rank);
            highestRequiredRank = Math.max(highestRequiredRank, card.rank);
        }
        for (const card of handCards) {
            if ((card.suit !== Suit.UNKNOWN || card.cardId === CardId.MAHJONG) && bestCardsByRankScores[card.rank - 1] !== 100) {
                const score = cardIdsKnownByOpps.includes(card.cardId) ? 30 : cardIdsKnownByPartner.includes(card.cardId) ? 10 : 20;
                if (score > bestCardsByRankScores[card.rank - 1]) {
                    bestCardsByRank[card.rank - 1] = card;
                    bestCardsByRankScores[card.rank - 1] = score;
                } else {
                    bestCardsByRankScores[card.rank - 1] += 1; // Mark that we passed up the opportunity to sub a card
                }
            }
        }
        // 2. Find the longest straight that spans the required ranks
        const lowestAllowedRank = winningCombination ? winningCombination.rank - winningCombination.cards.length + 2 : 1;
        let lowRank = lowestRequiredRank;
        let highRank = lowRank;
        while (highRank < 14 && bestCardsByRank[highRank - 1 + 1] !== null) {
            highRank++;
        }
        if (highRank < highestRequiredRank) {
            // Could not connect the highest required rank to the lowest one
            return [];
        }
        while (lowRank > lowestAllowedRank && bestCardsByRank[lowRank - 1 - 1] !== null) {
            lowRank--;
        }
        const minLength = winningCombination ? winningCombination.cards.length : 5;
        if (highRank - lowRank + 1 < minLength) {
            // Not long enough
            return [];
        }
        if (winningCombination === undefined) {
            const longStraightCards = bestCardsByRank.slice(lowRank - 1, highRank);
            if (new Set(longStraightCards.map((card) => card!.suit)).size === 1) {
                // If the whole thing is a bomb, don't bother
                return result;
            }
            result.push({
                combination_type: CombinationType.STRAIGHT,
                cards: longStraightCards.map((card) => card!.cardId),
                rank: highRank,
            });
            if (highRank - lowRank + 1 > minLength) {
                // Create the weakest, smallest straight we can
                const h = Math.max(highestRequiredRank, lowRank + 5 - 1);
                const l = Math.min(lowestRequiredRank, h - 5 + 1);
                const shortStraightCards = bestCardsByRank.slice(l - 1, h);
                if (new Set(shortStraightCards.map((card) => card!.suit)).size === 1) {
                    // If the whole thing is a bomb, don't bother
                    return result;
                }
                if (highRank !== h || lowRank !== l) {
                    // make sure it's not the same straight again...
                    result.push({
                        combination_type: CombinationType.STRAIGHT,
                        cards: shortStraightCards.map((card) => card!.cardId),
                        rank: h,
                    });
                }
            }
        } else {
            // We only care about straights of matching length.
            // First: the strongest one
            let bigH = highRank;
            let bigL = lowRank;
            while (bigH - bigL + 1 > minLength && bigL < lowestRequiredRank) {
                bigL++;
            }
            while (bigH - bigL + 1 > minLength && bigH > highestRequiredRank) {
                bigH--;
            }
            if (bigH - bigL + 1 === minLength) {
                const longStraightCards = bestCardsByRank.slice(bigL - 1, bigH);
                if (!(new Set(longStraightCards.map((card) => card!.suit)).size === 1)) {
                    result.push({
                        combination_type: CombinationType.STRAIGHT,
                        cards: longStraightCards.map((card) => card!.cardId),
                        rank: bigH,
                    });
                }
            }
            // Then: the weakest one
            let smallH = highRank;
            let smallL = lowRank;
            while (smallH - smallL + 1 > minLength && smallH > highestRequiredRank) {
                smallH--;
            }
            while (smallH - smallL + 1 > minLength && smallL < lowestRequiredRank) {
                smallL++;
            }
            if (smallH - smallL + 1 === minLength && !(smallH === bigH && smallL === bigL)) {
                const longStraightCards = bestCardsByRank.slice(smallL - 1, smallH);
                if (!(new Set(longStraightCards.map((card) => card!.suit)).size === 1)) {
                    result.push({
                        combination_type: CombinationType.STRAIGHT,
                        cards: longStraightCards.map((card) => card!.cardId),
                        rank: smallH,
                    });
                }
            }
        }

        return result;
    }

    public static _getBombSuggestions(
        selectedCards: CardDetails[],
        handCards: CardDetails[],
        winningCombination?: DmCombination,
        wish?: number
    ): DmCombination[] {
        if (selectedCards.length === 0) {
            // Don't suggest with nothing selected. TODO: Maybe we can still suggest?
            return [];
        }
        if (selectedCards[0].suit === Suit.UNKNOWN) {
            // Can't bomb with special cards.
            return [];
        }
        const result: DmCombination[] = [];
        const possibleRank = selectedCards[0].rank;
        const possibleSuit = selectedCards[0].suit;
        let rankBombPossible = selectedCards.length < 4 && (wish === undefined || wish === possibleRank);
        let suitBombPossible = true;
        for (const card of selectedCards) {
            if (card.rank !== possibleRank) {
                rankBombPossible = false;
            }
            if (card.suit !== possibleSuit) {
                suitBombPossible = false;
            }
        }
        if (rankBombPossible) {
            // check that all four cards are in the hand
            const cards = handCards.filter((card) => card.rank === possibleRank);
            if (cards.length === 4) {
                const winningCombinationIsBomb =
                    winningCombination !== undefined &&
                    (winningCombination.combination_type === CombinationType.QUAD_BOMB ||
                        winningCombination.combination_type === CombinationType.STRAIGHT_FLUSH_BOMB);
                const winningBombLength = winningCombinationIsBomb ? winningCombination.cards.length : 0;
                const winningBombRank = winningCombinationIsBomb ? winningCombination.rank : 0;
                if (!winningCombinationIsBomb || (winningBombLength === 4 && winningBombRank < possibleRank)) {
                    result.push({
                        combination_type: CombinationType.QUAD_BOMB,
                        cards: cards.map((card) => card.cardId),
                        rank: possibleRank,
                    });
                }
            }
        }
        if (suitBombPossible) {
            // find adjoining cards in suit
            const suitedCards = handCards.filter((card) => card.suit === possibleSuit);
            if (suitedCards.length >= 5) {
                const sortedCards = suitedCards.sort((a, b) => a.rank - b.rank);
                let leftIndex = 0;
                let rightIndex = 1;
                let requiredRanks: number[] | null = null; // lazy init - find any bomb first!
                while (rightIndex < sortedCards.length) {
                    while (
                        rightIndex + 1 < sortedCards.length &&
                        sortedCards[rightIndex + 1].rank - sortedCards[leftIndex].rank === rightIndex + 1 - leftIndex
                    ) {
                        rightIndex++;
                    }
                    const isRun = sortedCards[rightIndex].rank - sortedCards[leftIndex].rank === rightIndex - leftIndex;
                    if (isRun) {
                        if (rightIndex - leftIndex >= 4 && rightIndex - leftIndex + 1 > selectedCards.length) {
                            // This is a bomb. Check if it uses all selected cards.
                            if (requiredRanks === null) {
                                requiredRanks = selectedCards.map((card) => card.rank);
                                if (wish !== undefined && !requiredRanks.includes(wish)) {
                                    requiredRanks.push(wish);
                                }
                                requiredRanks.sort((a, b) => a - b);
                            }
                            if (
                                requiredRanks[0] >= sortedCards[leftIndex].rank &&
                                requiredRanks[requiredRanks.length - 1] <= sortedCards[rightIndex].rank
                            ) {
                                const length = rightIndex - leftIndex + 1;
                                const rank = sortedCards[rightIndex].rank;
                                const winningCombinationIsSfBomb =
                                    winningCombination !== undefined &&
                                    winningCombination.combination_type === CombinationType.STRAIGHT_FLUSH_BOMB;
                                const winningBombLength = winningCombinationIsSfBomb ? winningCombination.cards.length : 0;
                                const winningBombRank = winningCombinationIsSfBomb ? winningCombination.rank : 0;
                                if (
                                    !winningCombinationIsSfBomb ||
                                    (winningBombLength === length && winningBombRank < rank) ||
                                    winningBombLength < length
                                ) {
                                    result.push({
                                        combination_type: CombinationType.STRAIGHT_FLUSH_BOMB,
                                        cards: sortedCards.slice(leftIndex, rightIndex + 1).map((card) => card.cardId),
                                        rank: rank,
                                    });
                                    // We push up to two bombs:
                                    // 1) The longest one
                                    // 2) the weakest one - either a 4-bomb or the lowest possible 5-bomb that uses all the cards
                                    if (length > 5 && result.length === 1) {
                                        let minLength = 5;
                                        let minRank = 0;
                                        if (winningCombinationIsSfBomb) {
                                            minLength = winningBombLength;
                                            minRank = winningBombRank + 1;
                                            if (minRank > rank) {
                                                minLength += 1;
                                                minRank = 0;
                                            } else {
                                                const minLowestRank = minRank - minLength + 1;
                                                if (requiredRanks.some((r) => r < minLowestRank)) {
                                                    minLength += 1;
                                                    minRank = 0;
                                                }
                                            }
                                        }
                                        if (length > minLength) {
                                            while (
                                                rightIndex - leftIndex + 1 > minLength &&
                                                !requiredRanks.includes(sortedCards[rightIndex].rank) &&
                                                sortedCards[rightIndex].rank > minRank
                                            ) {
                                                rightIndex--;
                                            }
                                            while (
                                                rightIndex - leftIndex + 1 > minLength &&
                                                !requiredRanks.includes(sortedCards[leftIndex].rank)
                                            ) {
                                                leftIndex++;
                                            }
                                            result.unshift({
                                                combination_type: CombinationType.STRAIGHT_FLUSH_BOMB,
                                                cards: sortedCards.slice(leftIndex, rightIndex + 1).map((card) => card.cardId),
                                                rank: sortedCards[rightIndex].rank,
                                            });
                                        }
                                    }
                                }
                                // We found the connected group that has the required cards. There won't be more.
                                return result;
                            }
                        }
                    }

                    leftIndex = rightIndex;
                    rightIndex++;
                }
            }
        }
        return result;
    }
}
