import popReverb from "../assets/pop-reverb.wav";
import popHigh from "../assets/pop-high.wav";
import pop from "../assets/pop.mp3";

const bigDrumAudio = new Audio(popReverb);
const drumAudio = new Audio(popHigh);
const popAudio = new Audio(pop);

const allAudio = [bigDrumAudio, drumAudio, popAudio];
var unmuted = false;

// Listen for user interaction as playing audio before then is not ok.
function handleUserInteraction() {
    unmuted = true;
    // Remove event listeners once the user has interacted
    window.removeEventListener("click", handleUserInteraction);
    window.removeEventListener("keydown", handleUserInteraction);
    window.removeEventListener("touchstart", handleUserInteraction);
}

window.addEventListener("click", handleUserInteraction);
window.addEventListener("keydown", handleUserInteraction);
window.addEventListener("touchstart", handleUserInteraction);

export function setVolume(volume: number) {
    allAudio.forEach((audio) => {
        audio.volume = volume;
    });
}

setVolume(0.3);

export function playBigDrum() {
    if (unmuted) {
        bigDrumAudio.currentTime = 0;
        bigDrumAudio.play();
    }
}

export function playDrum() {
    if (unmuted) {
        drumAudio.currentTime = 0;
        drumAudio.play();
    }
}

export function playPop() {
    if (unmuted) {
        popAudio.currentTime = 0;
        popAudio.play();
    }
}
