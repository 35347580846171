import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';

ReactDOM.render(
    <React.StrictMode>
      <GoogleOAuthProvider clientId='418592993010-jdt1bv95f49287sn2ghbkl811u8h734k.apps.googleusercontent.com'>
      <App />
      </GoogleOAuthProvider>
    </React.StrictMode>,
    document.getElementById('root') as HTMLElement
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
