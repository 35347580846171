import React from "react";
import { NavBar } from "../components/NavBar";
import { UserContext } from "../UserContext";
import { LoadingDiv } from "../components/LoadingDiv";
import { Lobby } from "../components/Lobby";
import { Table } from "../components/Table";
import { DmGame, DmTable } from "../client/server-types-python";
import { ApiClient } from "../client/ApiClient";
import { WebSocketClient } from "../client/WebSocketClient";

type TablesPageProps = {
    initialLoadInProgressSince: number | null;
    tableId: string | null;
    tablesState: DmTable[];
    apiClient: ApiClient;
    joinTable: (tableId: string) => void;
    createTable: () => void;
    webSocketClient: WebSocketClient | null;
    game: DmGame | null;
    // children: React.ReactNode;
};

export function TablesPage(props: TablesPageProps): JSX.Element {
    const { initialLoadInProgressSince, tableId, tablesState, apiClient, joinTable, createTable, webSocketClient, game } = props;
    const userContext = React.useContext(UserContext);
    if (!userContext) {
        return <></>;
    }
    return (
        <>
            <NavBar />
            {initialLoadInProgressSince !== null ? (
                <LoadingDiv>Loading...</LoadingDiv>
            ) : tableId === null ? (
                <Lobby tables={tablesState} onCreateTable={createTable} onJoinTable={joinTable}></Lobby>
            ) : (
                <Table
                    apiClient={apiClient}
                    webSocketClient={webSocketClient}
                    user={userContext.user}
                    tableId={tableId}
                    tablesState={tablesState}
                    game={game}
                ></Table>
            )}
        </>
    );
}
