import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import React from "react";

export const DropdownParent = styled.div`
    position: relative;
    display: inline-block;
`;

const Dropdown = styled.div`
    display: block;
    position: absolute;
    right: 0px;
    top: 100%;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    button {
        display: block;
        width: 100%;
        padding: 12px 16px;
        text-decoration: none;
        background-color: ${(props) => props.theme.colors.primary_hinted_white};
        color: ${(props) => props.theme.colors.black_text};
        text-align: left;
        border: none;
    }

    button:hover {
        background-color: ${(props) => props.theme.colors.light};
    }
`;

export const DropdownItemButton = styled.button`
    display: block;
    width: 100%;
    padding: 12px 16px;
    text-decoration: none;
    background-color: ${(props) => props.theme.colors.primary_hinted_white};
    color: ${(props) => props.theme.colors.black_text};
    text-align: left;
    border: none;

    &:hover {
        background-color: ${(props) => props.theme.colors.primary_very_light};
    }
`;

export function DropdownMenu(props: React.PropsWithChildren<{ triggerButton: JSX.Element }>) {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const clonedTriggerButton = React.cloneElement(props.triggerButton, {
        onClick: toggleDropdown,
    });

    return (
        <DropdownParent ref={dropdownRef}>
            {clonedTriggerButton}
            {dropdownOpen && <Dropdown>{props.children}</Dropdown>}
        </DropdownParent>
    );
}
