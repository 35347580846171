import { motion } from "framer-motion";
import React, { ReactNode } from "react";
import { Alignment } from "./Seat";
import styled from "styled-components";

const StyledHand = styled(motion.div)<{ $alignment: Alignment }>`
    width: 512px;
    height: 60px;
    border: 1px dashed lightgray;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    ${({ $alignment }) => {
        switch ($alignment) {
            case Alignment.LEFT:
                return "height: 512px; width: 60px; flex-direction: column-reverse;";
            case Alignment.RIGHT:
                return "height: 512px; width: 60px; flex-direction: column-reverse;";
            default:
                return "";
        }
    }}
`;

interface HandProps {
    alignment: Alignment;
    children: ReactNode;
}

export const Hand = (props: HandProps) => {
    return (
        <StyledHand layout="position" $alignment={props.alignment}>
            {props.children}
        </StyledHand>
    );
};
