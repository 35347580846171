import React, { useEffect } from "react";
import { NavBar } from "../components/NavBar";
import { UserContext } from "../UserContext";
import { DmAuthResponse } from "../client/server-types-python";
import { ApiClient } from "../client/ApiClient";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Login } from "../components/Login";

const ParentDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 300px;
    align-items: center;
    justify-content: center;
    align-content: center;
`;

type ResetPasswordPageProps = {
    apiClient: ApiClient;
    setLoggedInCallback: (dmAuthResponse: DmAuthResponse) => void;
};

export function ResetPasswordPage(props: ResetPasswordPageProps): JSX.Element {
    const { setLoggedInCallback } = props;
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    const email = searchParams.get("email");
    const navigate = useNavigate();
    const userContextExists = React.useContext(UserContext) !== null;

    useEffect(() => {
        if (!token || !email || userContextExists) {
            navigate("/");
        }
    }, [token, email, userContextExists, navigate]);

    if (!token || !email) {
        return (
            <>
                <NavBar />
            </>
        );
    }

    const { apiClient } = props;
    return (
        <>
            <NavBar />

            <ParentDiv>
                <Login apiClient={apiClient} setLoggedInCallback={setLoggedInCallback} resetPasswordPageData={{ email, token }}></Login>
            </ParentDiv>
        </>
    );
}
