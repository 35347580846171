import { CardId } from "../client/basic-types";

export enum Suit {
    UNKNOWN = "UNKNOWN",
    BLACK = "BLACK",
    BLUE = "BLUE",
    GREEN = "GREEN",
    RED = "RED",
}

export class CardDetails {
    public cardId: CardId;
    public rank: number;
    public suit: Suit;
    public points: number;
    public sortOrder: number;

    constructor(cardId: CardId, rank: number, suit: Suit, points?: number, sortOrder?: number) {
        this.cardId = cardId;
        this.rank = rank;
        this.suit = suit;
        this.points = points !== undefined ? points : 0;
        this.sortOrder = sortOrder !== undefined ? sortOrder : rank * 10 + CardDetails.SUIT_ORDER[suit];
    }

    private static readonly SUIT_ORDER: { [key in Suit]: number } = {
        [Suit.UNKNOWN]: 0,
        [Suit.BLACK]: 1,
        [Suit.BLUE]: 2,
        [Suit.GREEN]: 3,
        [Suit.RED]: 4,
    };

    private static ALL_CARDS: CardDetails[] = [
        new CardDetails(CardId.PHOENIX, 1, Suit.UNKNOWN, -25, 0),
        new CardDetails(CardId.DRAGON, 15, Suit.UNKNOWN, 25),
        new CardDetails(CardId.DOG, 1, Suit.UNKNOWN, undefined, 5),
        new CardDetails(CardId.MAHJONG, 1, Suit.UNKNOWN),
        new CardDetails(CardId.BLACK_2, 2, Suit.BLACK),
        new CardDetails(CardId.BLACK_3, 3, Suit.BLACK),
        new CardDetails(CardId.BLACK_4, 4, Suit.BLACK),
        new CardDetails(CardId.BLACK_5, 5, Suit.BLACK, 5),
        new CardDetails(CardId.BLACK_6, 6, Suit.BLACK),
        new CardDetails(CardId.BLACK_7, 7, Suit.BLACK),
        new CardDetails(CardId.BLACK_8, 8, Suit.BLACK),
        new CardDetails(CardId.BLACK_9, 9, Suit.BLACK),
        new CardDetails(CardId.BLACK_10, 10, Suit.BLACK, 10),
        new CardDetails(CardId.BLACK_JACK, 11, Suit.BLACK),
        new CardDetails(CardId.BLACK_QUEEN, 12, Suit.BLACK),
        new CardDetails(CardId.BLACK_KING, 13, Suit.BLACK, 10),
        new CardDetails(CardId.BLACK_ACE, 14, Suit.BLACK),
        new CardDetails(CardId.BLUE_2, 2, Suit.BLUE),
        new CardDetails(CardId.BLUE_3, 3, Suit.BLUE),
        new CardDetails(CardId.BLUE_4, 4, Suit.BLUE),
        new CardDetails(CardId.BLUE_5, 5, Suit.BLUE, 5),
        new CardDetails(CardId.BLUE_6, 6, Suit.BLUE),
        new CardDetails(CardId.BLUE_7, 7, Suit.BLUE),
        new CardDetails(CardId.BLUE_8, 8, Suit.BLUE),
        new CardDetails(CardId.BLUE_9, 9, Suit.BLUE),
        new CardDetails(CardId.BLUE_10, 10, Suit.BLUE, 10),
        new CardDetails(CardId.BLUE_JACK, 11, Suit.BLUE),
        new CardDetails(CardId.BLUE_QUEEN, 12, Suit.BLUE),
        new CardDetails(CardId.BLUE_KING, 13, Suit.BLUE, 10),
        new CardDetails(CardId.BLUE_ACE, 14, Suit.BLUE),
        new CardDetails(CardId.GREEN_2, 2, Suit.GREEN),
        new CardDetails(CardId.GREEN_3, 3, Suit.GREEN),
        new CardDetails(CardId.GREEN_4, 4, Suit.GREEN),
        new CardDetails(CardId.GREEN_5, 5, Suit.GREEN, 5),
        new CardDetails(CardId.GREEN_6, 6, Suit.GREEN),
        new CardDetails(CardId.GREEN_7, 7, Suit.GREEN),
        new CardDetails(CardId.GREEN_8, 8, Suit.GREEN),
        new CardDetails(CardId.GREEN_9, 9, Suit.GREEN),
        new CardDetails(CardId.GREEN_10, 10, Suit.GREEN, 10),
        new CardDetails(CardId.GREEN_JACK, 11, Suit.GREEN),
        new CardDetails(CardId.GREEN_QUEEN, 12, Suit.GREEN),
        new CardDetails(CardId.GREEN_KING, 13, Suit.GREEN, 10),
        new CardDetails(CardId.GREEN_ACE, 14, Suit.GREEN),
        new CardDetails(CardId.RED_2, 2, Suit.RED),
        new CardDetails(CardId.RED_3, 3, Suit.RED),
        new CardDetails(CardId.RED_4, 4, Suit.RED),
        new CardDetails(CardId.RED_5, 5, Suit.RED, 5),
        new CardDetails(CardId.RED_6, 6, Suit.RED),
        new CardDetails(CardId.RED_7, 7, Suit.RED),
        new CardDetails(CardId.RED_8, 8, Suit.RED),
        new CardDetails(CardId.RED_9, 9, Suit.RED),
        new CardDetails(CardId.RED_10, 10, Suit.RED, 10),
        new CardDetails(CardId.RED_JACK, 11, Suit.RED),
        new CardDetails(CardId.RED_QUEEN, 12, Suit.RED),
        new CardDetails(CardId.RED_KING, 13, Suit.RED, 10),
        new CardDetails(CardId.RED_ACE, 14, Suit.RED),
    ];

    public static getCardDetails(card: CardId): CardDetails {
        return this.ALL_CARDS[card];
    }
}
