import { CardDetails, Suit } from "../../model/CardDetails";
import "../../styles/game.css";
import { CardId } from "../../client/basic-types";
import { motion } from "framer-motion";
import InlineIcon from "./InlineIcon";

export const RANKS = ["", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "J", "Q", "K", "A"];

interface CardProps {
    cardDetails: CardDetails | null;
    onClick?: (cardId: CardId) => void;
    selected?: boolean;
    textOnly: boolean;
    onClickWithNoCardDetails?: () => void;
    tradeDirection?: "left" | "center" | "right";
    contextId?: string; // Used as part of layout id so two of the same card are considered the same.
    inPlayLimbo?: boolean;
    sideAlignment?: "left" | "right";
}

export const Card = (props: CardProps): JSX.Element => {
    const renderTextOnly = () => {
        if (props.cardDetails === null) {
            return <span className="cardTextOnly">?</span>;
        }
        const className = "cardTextOnly " + getSuitClass(props.cardDetails);
        return <span className={className}>{getCardString()}</span>;
    };

    const renderNormal = () => {
        const marginBottomPx = props.selected ? 24 : 0;
        const className =
            "card " +
            getSuitClass(props.cardDetails) +
            (props.sideAlignment ? ` align_${props.sideAlignment}` : "") +
            (props.cardDetails === null ? (props.tradeDirection ? ` ${props.tradeDirection}_trade` : " unknown") : "");
        const suitSymbol = getSuitSymbol(props.cardDetails?.suit ?? Suit.UNKNOWN);
        const onClick = () =>
            props.cardDetails && props.onClick
                ? props.onClick(props.cardDetails.cardId)
                : props.cardDetails === null && props.onClickWithNoCardDetails
                ? props.onClickWithNoCardDetails()
                : {};
        const transition = { duration: props.inPlayLimbo ? 1.8 : 0.4, ease: "circOut" };
        return (
            <motion.button
                layoutId={props.contextId && props.cardDetails ? props.contextId + props.cardDetails.cardId : undefined}
                className={className}
                onClick={onClick}
                initial={false}
                animate={{
                    marginBottom: marginBottomPx + "px",
                    transition: { type: "spring", duration: 0.2, bounce: 0.15 },
                }}
                transition={transition}
                layout={"position"}
            >
                {getCardString()}
                {suitSymbol ? (
                    <div
                        className={
                            props.sideAlignment === "left"
                                ? "leftSuitSymbol"
                                : props.sideAlignment === "right"
                                ? "rightSuitSymbol"
                                : "suitSymbol"
                        }
                    >
                        {suitSymbol}
                    </div>
                ) : undefined}
            </motion.button>
        );
    };

    const getSuitClass = (cardDetails: CardDetails | null) => {
        const suit = cardDetails?.suit ?? Suit.UNKNOWN;
        const special = cardDetails && cardDetails.cardId <= 3;
        if (special) {
            return "suitSpecial";
        }
        switch (suit) {
            case Suit.BLACK:
                return "suitBlack";
            case Suit.BLUE:
                return "suitBlue";
            case Suit.GREEN:
                return "suitGreen";
            case Suit.RED:
                return "suitRed";
            default:
                return "suitNone";
        }
    };

    const getSuitSymbol = (suit: Suit) => {
        switch (suit) {
            case Suit.BLACK:
                return <InlineIcon iconName="black" />;
            case Suit.BLUE:
                return <InlineIcon iconName="blue" />;
            case Suit.GREEN:
                return <InlineIcon iconName="green" />;
            case Suit.RED:
                return <InlineIcon iconName="red" />;
            default:
                return undefined;
        }
    };

    const getCardString = () => {
        if (props.cardDetails === null) {
            return "";
        }
        switch (props.cardDetails.cardId) {
            case CardId.PHOENIX:
                return <InlineIcon iconName="phoenix" />;
            case CardId.DRAGON:
                return <InlineIcon iconName="dragon" />;
            case CardId.DOG:
                return <InlineIcon iconName="dog" />;
            case CardId.MAHJONG:
                return "1";
            default:
        }
        switch (props.cardDetails.rank) {
            case 0:
                return "";
            case 10:
                return "T";
            case 11:
                return "J";
            case 12:
                return "Q";
            case 13:
                return "K";
            case 14:
                return "A";
            default:
                return props.cardDetails.rank;
        }
    };

    if (props.textOnly) {
        return renderTextOnly();
    }
    return renderNormal();
};
