import React from "react";
import styled from "styled-components";

const OverallDivWithControls = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: white;
`;

const CenterDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
`;

const MainDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
`;

const ActiveAreasDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
`;

const ActiveAreasCenterRowDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
`;

export class GameLayout extends React.Component<{
    seats: React.ReactNode[];
    activeAreas: React.ReactNode[];
    trickInfo: React.ReactNode;
    controls: React.ReactNode;
}> {
    render() {
        const { seats, activeAreas, trickInfo, controls } = this.props;
        return (
            <OverallDivWithControls>
                <MainDiv>
                    {seats[1]}
                    <CenterDiv>
                        {seats[2]}
                        <ActiveAreasDiv>
                            {activeAreas[2]}
                            <ActiveAreasCenterRowDiv>
                                {activeAreas[1]}
                                {trickInfo}
                                {activeAreas[3]}
                            </ActiveAreasCenterRowDiv>
                            {activeAreas[0]}
                        </ActiveAreasDiv>
                        {seats[0]}
                    </CenterDiv>
                    {seats[3]}
                </MainDiv>
                {controls}
            </OverallDivWithControls>
        );
    }
}
