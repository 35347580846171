import { DmGame, DmHistoryEntry } from "../client/server-types-python";

export const getHandHistory = (game: DmGame, handIndex: number, handHistoryCache: (DmHistoryEntry[] | null)[]): DmHistoryEntry[] | null => {
    const gameHands = game.game_hands;
    if (gameHands.length === 0) {
        return [];
    }
    if (handIndex < 0 || handIndex >= gameHands.length) {
        return null;
    }
    const history = gameHands[handIndex].history;
    if (history !== null) {
        return history;
    }
    if (handHistoryCache.length <= handIndex) {
        return null;
    }
    return handHistoryCache[handIndex];
};
