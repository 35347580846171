import React from "react";
import styled from "styled-components";
import bet_split from "../../assets/bet-split.png";
import bet_gray from "../../assets/bet-gray.png";
import bet_black from "../../assets/bet-black.png";
import bet_white from "../../assets/bet-white.png";
import bet_blue_question from "../../assets/bet-blue-question.png";
import bet_blue_butterfly from "../../assets/bet-blue-butterfly.png";
import bet_gold from "../../assets/bet-gold.png";
import bet_gold_plain from "../../assets/bet-gold-plain.png";

// small square component that displays a bet button if the bet is available
// has a few different states:
// - betActive: true if the bet has been called
// - betAvailable: true if the bet is available
const BetImageDiv = styled.button<{ $url: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border: 0px solid black;
    margin: 0px;
    background-image: url(${(props) => props.$url});
    background-size: 100% 100%;
    font-size: 18px;
    color: #ffffff;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
`;

const ClickableBetDiv = styled(BetImageDiv)<{ $actuallyClickable: boolean }>`
    font-size: 12px;
    color: transparent;
    user-select: none;
    cursor: default;

    ${(props) =>
        props.$actuallyClickable &&
        `
        

        &:hover {
            background-image: url(${bet_gold_plain});
            color: black;
            user-select: auto;
        }

        &:active {
            background-image: url(${bet_gold_plain});
        }
    `}
`;

export class Bet extends React.Component<{
    betActive: boolean;
    betAvailable: boolean;
    isReplayMode: boolean;
    isPlayer: boolean;
    isActive: boolean;
    finishedOrder: number;
    anotherPlayerIsOutFirst: boolean;
    tradePhase: boolean;
    betCallback: () => void;
}> {
    render() {
        const {
            betActive,
            betAvailable,
            isReplayMode,
            isPlayer,
            isActive,
            finishedOrder,
            anotherPlayerIsOutFirst,
            tradePhase,
            betCallback,
        } = this.props;
        if (finishedOrder !== 0) {
            if (betActive) {
                if (finishedOrder === 1) {
                    return <BetImageDiv $url={bet_white}>{finishedOrder}</BetImageDiv>;
                } else {
                    return <BetImageDiv $url={bet_black}>{finishedOrder}</BetImageDiv>;
                }
            } else {
                return <BetImageDiv $url={bet_gray}>{finishedOrder}</BetImageDiv>;
            }
        } else if (betActive) {
            if (anotherPlayerIsOutFirst) {
                return <BetImageDiv $url={bet_black}></BetImageDiv>;
            }
            return <BetImageDiv $url={bet_split}></BetImageDiv>;
        } else if (betAvailable && isPlayer && isActive && !tradePhase) {
            return (
                <ClickableBetDiv $url={bet_gold} $actuallyClickable={!isReplayMode} onClick={() => !isReplayMode && betCallback()}>
                    BET
                </ClickableBetDiv>
            );
        } else if (betAvailable) {
            return <BetImageDiv $url={bet_blue_question}></BetImageDiv>;
        } else {
            return <BetImageDiv $url={bet_blue_butterfly}></BetImageDiv>;
        }
    }
}
