import { DmTableParticipant } from "./server-types-python";

export const getName = (participant: DmTableParticipant | null) => {
    if (!participant) {
        return "---";
    }
    return participant.bot_name ?? participant.user?.display_name ?? `? (${participant.seat})`;
};

export const getUserSettingSpeedLabel = (speed: 0 | 1 | 2 | 3 | 4) => {
    switch (speed) {
        case 0:
            return "Slowest";
        case 1:
            return "Slower";
        case 2:
            return "Normal";
        case 3:
            return "Faster";
        case 4:
            return "Fastest";
    }
};

export const getUserSettingVolumeLabel = (volume: 0 | 1 | 2 | 3 | 4) => {
    switch (volume) {
        case 0:
            return "Muted";
        case 1:
            return "Low";
        case 2:
            return "Medium";
        case 3:
            return "High";
        case 4:
            return "Max";
    }
};
