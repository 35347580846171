import React from "react";

export class Pass extends React.Component {
    render() {
      return (
        <div className="pass">
          PASS
        </div>
      );
    }
  }