import React from "react";
import styled from "styled-components";
import { DmPlayState } from "../../client/server-types-python";
import { CardDetails } from "../../model/CardDetails";
import { RANKS } from "./Card";

const TrickInfoDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dashed lightgray;
    width: 60px;
    height: 60px;
    margin: 12px;
    border-radius: 8px;
    font-size: 20px;
    padding: 0px;
`;

const Diamond = styled.span`
    font-size: "0.8em";
`;

const WishDiv = styled.div`
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    color: ${(props) => props.theme.colors.wish_accent};
    padding: 0px;
`;

export class TrickInfo extends React.Component<{
    playState: DmPlayState;
}> {
    render() {
        const { playState } = this.props;
        const wish = RANKS[playState.wish_rank ?? 0];
        const pointsTotal = playState.current_cards.map((cardId) => CardDetails.getCardDetails(cardId).points).reduce((a, b) => a + b, 0);
        return (
            <TrickInfoDiv>
                <div>
                    {wish && <WishDiv>{`Wish: ${wish}`}</WishDiv>}
                    {pointsTotal === 0 ? "" : `${pointsTotal}`}
                    {pointsTotal !== 0 && <Diamond>◈</Diamond>}
                </div>
            </TrickInfoDiv>
        );
    }
}
