import { ActionId, CardId, PosId, Quartet, Rank } from "./basic-types";

export interface DmUserSettings {
    volume: 0 | 1 | 2 | 3 | 4;
    speed: 0 | 1 | 2 | 3 | 4;
}
export interface DmUser {
    id: string;
    email: string;
    display_name: string;
    user_settings?: DmUserSettings;
}

export enum CombinationType {
    PASS = 0,
    SINGLE = 1,
    PAIR = 2,
    TRIPLE = 3,
    STAIRS = 4,
    FULL_HOUSE = 5,
    STRAIGHT = 6,
    QUAD_BOMB = 7,
    STRAIGHT_FLUSH_BOMB = 8,
    DOG = 9,
    GIVE_DRAGON = 10,
    MAKE_WISH = 11,
    BET = 12,
}

export interface DmCombination {
    combination_type: CombinationType;
    cards: CardId[];
    rank: number;
}

export interface DmMove {
    position: PosId;
    combination: DmCombination;
    bet: boolean;
    wish_rank: Rank | null;
    give_dragon_pos: PosId | null;
    win_trick: boolean;
}

export type DmTradeSlate = CardId[];

export interface DmTradeSlateChoice {
    position: PosId;
    trade_slate: DmTradeSlate | null; // null to cancel the trade
}

export interface DmPosition {
    hand_cards: CardId[];
    trick_cards: CardId[];
    bet: boolean; // True means the player has called Tichu
    finished_order: 0 | 1 | 2 | 3 | 4; // 0 if not finished, 1-4 if finished
    trade_slate: DmTradeSlate | null;
}

export interface DmPlayState {
    positions: Quartet<DmPosition>;
    current_cards: CardId[];
    active_player: PosId;
    winning_player: PosId | null;
    winning_combination: DmCombination | null;
    wish_rank: Rank | null;
    give_dragon: boolean; // True means the player has to give the dragon
    make_wish: boolean; // True means the player has to make a wish
    is_engine_turn: boolean; // True if the next move needs to be by the engine
    is_trade_phase: boolean;
    is_hand_over: boolean;
    ns_score: number;
    ew_score: number;
}

export interface DmHistoryEntry {
    action_id: ActionId | null;
    move: DmMove | null;
    state: DmPlayState;
    index: number;
}

export interface DmGameHand {
    id: string;
    index: number;
    play_state: DmPlayState;
    history: DmHistoryEntry[] | null; // only included some of the time
}

export enum DmGameRequestType {
    UNDO = 0,
    ABORT = 1,
}

export interface DmGameRequestBase {
    id: string;
    type: DmGameRequestType;
    initiator: DmUser;
    approvers: DmUser[];
    canceller: DmUser | null;
    pending: boolean;
}

export type DmUndoRequest = DmGameRequestBase & {
    type: DmGameRequestType.UNDO;
    data: {
        hand_index: number;
        move_index: number;
    };
};

export type DmAbortRequest = DmGameRequestBase & {
    type: DmGameRequestType.ABORT;
    data: {};
};

export type DmGameRequest = DmUndoRequest | DmAbortRequest;

export enum GameMode {
    NONE = 0,
    ONE_HAND = 1,
    STANDARD = 2,
    SHORT_600 = 3,
    FIXED_8 = 4,
    DUPLICATE = 5,
}

export enum GameStatus {
    NOT_STARTED = 0,
    MID_HAND = 1,
    READY_FOR_NEXT_HAND = 2,
    COMPLETE = 3,
}

export interface DmGame {
    mode: GameMode;
    status: GameStatus;
    game_hands: DmGameHand[];
    game_requests: DmGameRequest[];
}

export enum BotType {
    NONE = 0,
    RANDOM = 1,
    BASIC = 2,
    EVAL = 3,
    SEARCH = 4,
    NEURAL_NETWORK = 5,
    NN_MANY_INPUTS = 6,
    NN_TEMP = 7,
    MIXED_EVAL_NNMI_BOT = 8,
    MCTS_BOT = 9,
    MCTS_BOT_2 = 10,
}

export interface DmTableParticipant {
    seat: PosId;
    user: DmUser | null;
    bot_type: BotType | null;
    bot_name: string | null;
}

export enum TableStatus {
    OPEN = 0,
    READY = 1,
    PLAYING = 2,
    LIMBO = 3,
    FINISHED = 4,
}

export interface DmTableSettings {
    game_mode: GameMode;
    shuffle_players: 0 | 1; // 0 = fixed, 1 = fully shuffle. Could add more options later.
}

export interface DmTable {
    id: string;
    players: Quartet<DmTableParticipant | null>;
    game: DmGame;
    settings: DmTableSettings;
    status: TableStatus;
}

export enum DmLobbyEventType {
    TABLE_CREATED = 0,
    TABLE_UPDATED = 1,
    TABLE_CLOSED = 2,
}

export enum DmTableEventType {
    GAME_STARTED = 3,
    GAME_UPDATED = 4,
    GAME_REWOUND = 5,
    GAME_ABORTED = 6,
    GAME_REQUEST_CHANGE = 7,
}

export enum DmGameEventType {
    GAME_MOVE = 8,
}

export interface DmLobbyEvent {
    type: DmLobbyEventType;
    table: DmTable;
}
export interface DmTableEvent {
    type: DmTableEventType;
    game: DmGame;
}
export interface DmGameEvent {
    type: DmGameEventType;
    entry: DmHistoryEntry;
}
export interface DmAuthResponse {
    user: DmUser;
    access_token: string;
}
