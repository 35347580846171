import React, { useEffect, useRef, useState } from "react";
import { CombinationType, DmTableParticipant, DmTable, DmHistoryEntry, GameStatus } from "../../client/server-types-python";

import styled from "styled-components";
import { CardId, Quartet } from "../../client/basic-types";
import { CardDetails } from "../../model/CardDetails";
import { Card, RANKS } from "./Card";
import { NowplayState, ReplayState } from "../../model/ReplayState";
import { getName } from "../../client/util";
import { getHandHistory } from "../../model/HandHistoryUtil";
import theme from "../../theme";

interface Theme {
    colors: typeof theme.colors;
}

interface HistoryPanelDivProps {
    $replayMode: boolean;
    theme: Theme;
}

const getBackgroundColor = (props: HistoryPanelDivProps) =>
    props.$replayMode ? props.theme.colors.alternate_very_light : props.theme.colors.primary_very_light;
const getScrollbarColor = (props: HistoryPanelDivProps) => (props.$replayMode ? props.theme.colors.alternate : props.theme.colors.primary);
const getScrollbarHintColor = (props: HistoryPanelDivProps) =>
    props.$replayMode ? props.theme.colors.alternate_hinted_white : props.theme.colors.primary_hinted_white;

const HistoryPanelDiv = styled.div<{ $replayMode: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 250px;
    height: 530px;
    margin: 12px;
    font-size: 14px;
    padding: 0px;
    background-color: ${getBackgroundColor};
    scrollbar-color: ${getScrollbarColor} ${getScrollbarHintColor};
`;

const ScrollableArea = styled.div`
    overflow-y: auto;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const ScrollableAreaInner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 8px;
`;

const HistoryPanelControls = styled.div`
    font-size: 20px;
    padding: 6px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 12px;
`;

const HistoryPanelControlButton = styled.button`
    background-color: ${(props) => props.theme.colors.primary_dark};
    border: none;
    border-radius: 6px;
    color: ${(props) => props.theme.colors.white_text};
    padding: 0px 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 3px;
    height: 36px;
    line-height: 1; /* Adjust as needed */
    vertical-align: middle; /* Aligns the button vertically in the middle */

    &:hover {
        background-color: ${(props) => props.theme.colors.primary_darker};
    }

    &:disabled {
        background-color: ${(props) => props.theme.colors.primary_dull};
        color: ${(props) => props.theme.colors.white_text_dull};
        pointer-events: none;
    }
`;

const ReplayControlButton = styled(HistoryPanelControlButton)`
    background-color: ${(props) => props.theme.colors.alternate};

    &:hover {
        background-color: ${(props) => props.theme.colors.alternate_light};
    }

    &:active {
        background-color: ${(props) => props.theme.colors.alternate_light};
    }

    &:disabled {
        background-color: ${(props) => props.theme.colors.alternate_dull};
        color: ${(props) => props.theme.colors.white_text_dull};
        pointer-events: none;
    }
`;

const ScoreDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 9px 9px 0px 9px;
    box-sizing: border-box;
`;

const ScoreButton = styled(HistoryPanelControlButton)`
    width: 100%;
    box-sizing: border-box;
    margin: 0px;
    background-color: ${(props) => props.theme.colors.score_header};
    white-space: "nowrap";
    border-radius: 6px 0px 0px 6px;

    &:hover {
        background-color: ${(props) => props.theme.colors.score_header_light};
    }

    &:active {
        background-color: ${(props) => props.theme.colors.score_header_light};
    }
`;

const DropdownButton = styled(HistoryPanelControlButton)`
    position: relative;
    display: inline-block;
    margin: 0px 0px 0px 6px;
    border-radius: 0px 6px 6px 0px;
`;

const DropdownMenuParent = styled.div`
    position: relative;
    display: inline-block;
`;

const HistoryPanelDropdown = styled.div`
    display: block;
    position: absolute;
    right: 0px;
    top: 100%;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    button {
        display: block;
        width: 100%;
        padding: 12px 16px;
        text-decoration: none;
        background-color: ${(props) => props.theme.colors.primary_hinted_white};
        color: ${(props) => props.theme.colors.black_text};
        text-align: left;
        border: none;
    }

    button:hover {
        background-color: ${(props) => props.theme.colors.light};
    }
`;

const DropdownItemButton = styled.button`
    display: block;
    width: 100%;
    padding: 12px 16px;
    text-decoration: none;
    background-color: ${(props) => props.theme.colors.primary_hinted_white};
    color: ${(props) => props.theme.colors.black_text};
    text-align: left;
    border: none;

    &:hover {
        background-color: ${(props) => props.theme.colors.primary_very_light};
    }
`;

const MoveItem = styled.div`
    text-align: left;
`;

const PlayerAttribution = styled.span`
    font-weight: bold;
`;

const Scoreboard = styled.table`
    margin: 16px 16px 0px 16px;
    padding: 4px;
    border: 1px dashed black;
    border-radius: 8px;
`;

const ScoreboardCell = styled.td`
    padding: 4px 8px;
    font-size: 14px;
    font-weight: bold;
    min-width: 40px;
`;

export type HistoryPanelProps = {
    table: DmTable;
    handHistoryCache: (DmHistoryEntry[] | null)[];
    replayState: ReplayState;
    setReplayState: (replayState: ReplayState) => void;
    nowplayState: NowplayState;
    setNowplayState: (nowplayState: NowplayState) => void;
    leaveTable: () => void;
    requestUndo: (hand: number, move: number) => void;
    requestAbort: () => void;
    nextHandCallback: () => void;
    cardsGiven: CardId[] | null;
    cardsReceived: CardId[] | null;
    playerIsNs: boolean;
    openScoreModal: () => void;
};

export const HistoryPanel = ({
    table,
    handHistoryCache,
    replayState,
    setReplayState,
    nowplayState,
    setNowplayState,
    requestUndo,
    requestAbort,
    leaveTable,
    nextHandCallback,
    cardsGiven,
    cardsReceived,
    playerIsNs,
    openScoreModal,
}: HistoryPanelProps): JSX.Element => {
    const scrollableAreaRef = useRef<HTMLDivElement | null>(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const game = table.game;

    const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const handIndex = replayState.replayModeEnabled ? replayState.replayHandCursor : nowplayState.nowplayHandCursor;
    const fullHandHistory = getHandHistory(game, handIndex, handHistoryCache) ?? [];
    const handHistory = fullHandHistory.slice(
        0,
        (replayState.replayModeEnabled ? replayState.replayMoveCursor : nowplayState.nowplayMoveCursor) + 1
    );

    const canStepBack = replayState.replayMoveCursor > 0;
    const replayStepBack = () => {
        if (canStepBack) {
            setReplayState({ ...replayState, replayMoveCursor: replayState.replayMoveCursor - 1 });
        }
    };

    const canStepForward = replayState.replayMoveCursor < fullHandHistory.length - 1;
    const replayStepForward = () => {
        if (canStepForward) {
            setReplayState({ ...replayState, replayMoveCursor: replayState.replayMoveCursor + 1 });
        }
    };

    const canRewindHand = replayState.replayMoveCursor > 0;
    const canStepBackToPreviousHand = replayState.replayHandCursor > 0;
    const replayJumpBack = () => {
        if (canRewindHand) {
            setReplayState({ ...replayState, replayMoveCursor: 0 });
        } else if (canStepBackToPreviousHand) {
            setReplayState({ ...replayState, replayHandCursor: replayState.replayHandCursor - 1 });
        }
    };

    const canFastForwardHand = replayState.replayMoveCursor < fullHandHistory.length - 1;
    const canStepForwardToNextHand = replayState.replayHandCursor < game.game_hands.length - 1;
    const replayJumpForward = () => {
        if (canFastForwardHand) {
            setReplayState({ ...replayState, replayMoveCursor: fullHandHistory.length - 1 });
        } else if (canStepForwardToNextHand) {
            setReplayState({ ...replayState, replayHandCursor: replayState.replayHandCursor + 1, replayMoveCursor: 0 });
        }
    };

    const enterReplayModeAtMinusOne = () => {
        setReplayState({
            replayModeEnabled: true,
            replayHandCursor: nowplayState.nowplayHandCursor,
            replayMoveCursor: Math.max(0, nowplayState.nowplayMoveCursor - 1),
        });
    };

    const exitReplayMode = () => {
        setReplayState({ ...replayState, replayModeEnabled: false });
    };
    const gameHandIndex = replayState.replayModeEnabled ? replayState.replayHandCursor : nowplayState.nowplayHandCursor;
    const moveIndex = handHistory.length - 1;
    const playState = handHistory[moveIndex].state;

    const playerNames = table.players.map((player) => (player ? getName(player) : "(open seat)"));

    useEffect(() => {
        if (scrollableAreaRef.current) {
            scrollableAreaRef.current.scrollTop = scrollableAreaRef.current.scrollHeight;
        }
    }, [gameHandIndex, moveIndex, handHistory, table.players]); // replace with the variables that change when new content is added

    if (!game || game.game_hands.length === 0) {
        return <HistoryPanelDiv $replayMode={replayState.replayModeEnabled}></HistoryPanelDiv>;
    }

    const renderHandHistory = (history: DmHistoryEntry[], players: Quartet<DmTableParticipant | null>) => {
        const moveHistory = history.map((entry) => entry.move);
        const moveDivs = moveHistory.map((move, index) => {
            if (move === null) {
                return null;
            }
            const player = players[move.position];
            const playerText = player ? getName(player) : ["S", "W", "N", "E"][move.position];
            let moveContent = null;
            if (move.win_trick) {
                moveContent = "collects the trick";
            } else if (move.combination.combination_type === CombinationType.PASS) {
                moveContent = "pass";
            } else if (move.combination.combination_type === CombinationType.BET) {
                moveContent = "Taichu!";
            } else if (move.combination.combination_type === CombinationType.GIVE_DRAGON && move.give_dragon_pos !== null) {
                moveContent = "gives Dragon to " + getName(players[move.give_dragon_pos]);
            } else if (move.combination.combination_type === CombinationType.MAKE_WISH) {
                if (move.wish_rank !== null) {
                    moveContent = "wish for " + RANKS[move.wish_rank];
                } else {
                    moveContent = "(no wish)";
                }
            } else {
                moveContent = move.combination.cards.map((card, cardIndex) => (
                    <Card cardDetails={CardDetails.getCardDetails(card)} textOnly={true} key={"card." + cardIndex}></Card>
                ));
            }
            return (
                <MoveItem key={"move." + index}>
                    <PlayerAttribution>{playerText + ": "}</PlayerAttribution>
                    {moveContent}
                </MoveItem>
            );
        });

        if (cardsReceived !== null) {
            moveDivs.unshift(
                <MoveItem key={"cardsReceived"}>
                    <PlayerAttribution>{"You got: "}</PlayerAttribution>
                    {cardsReceived.map((card, cardIndex) => (
                        <>
                            <Card cardDetails={CardDetails.getCardDetails(card)} textOnly={true} key={"card." + cardIndex}></Card>{" "}
                        </>
                    ))}
                </MoveItem>
            );
        }

        if (cardsGiven !== null) {
            const gaveText = cardsReceived === null ? "You are giving: " : "You gave: ";
            moveDivs.unshift(
                <MoveItem key={"cardsGiven"}>
                    <PlayerAttribution>{gaveText}</PlayerAttribution>
                    {cardsGiven.map((card, cardIndex) => (
                        <>
                            <Card cardDetails={CardDetails.getCardDetails(card)} textOnly={true} key={"card." + cardIndex}></Card>{" "}
                        </>
                    ))}
                </MoveItem>
            );
        }

        return <ScrollableAreaInner>{moveDivs}</ScrollableAreaInner>;
    };

    const renderScoreboard = (playerNames: string[], nextHandCallback: () => void, replayMode: boolean, isGameOver: boolean) => {
        return (
            <>
                <Scoreboard>
                    <tbody>
                        <tr>
                            <ScoreboardCell>{playerNames[0] + " / " + playerNames[2]}</ScoreboardCell>
                            <ScoreboardCell>{playState.ns_score + " ◈"}</ScoreboardCell>
                        </tr>
                        <tr>
                            <ScoreboardCell>{playerNames[1] + " / " + playerNames[3]}</ScoreboardCell>
                            <ScoreboardCell>{playState.ew_score + " ◈"}</ScoreboardCell>
                        </tr>
                    </tbody>
                </Scoreboard>
                {replayMode ? (
                    <br />
                ) : isGameOver ? (
                    <button className="nextHandButton easyButton" onClick={openScoreModal}>
                        {"View Full Scoreboard"}
                    </button>
                ) : (
                    <button onClick={nextHandCallback} className="nextHandButton easyButton">
                        {"Start Next Hand"}
                    </button>
                )}
            </>
        );
    };

    const nsScore = game.game_hands.map((hand) => (hand.play_state.is_hand_over ? hand.play_state.ns_score : 0)).reduce((a, b) => a + b, 0);
    const ewScore = game.game_hands.map((hand) => (hand.play_state.is_hand_over ? hand.play_state.ew_score : 0)).reduce((a, b) => a + b, 0);
    const ourScore = playerIsNs ? nsScore : ewScore;
    const theirScore = playerIsNs ? ewScore : nsScore;
    const isGameOver =
        game.status === GameStatus.COMPLETE && game.game_hands.length - 1 === gameHandIndex && moveIndex === handHistory.length - 1;
    // game.status === GameStatus.COMPLETE && game.game_hands.length - 1 === gameHandIndex && moveIndex === handHistory.length - 1;

    const requestUndoFunc = replayState.replayModeEnabled
        ? () => requestUndo(replayState.replayHandCursor, replayState.replayMoveCursor)
        : moveIndex > 0
        ? () => requestUndo(gameHandIndex, moveIndex - 1)
        : gameHandIndex > 0
        ? () => requestUndo(gameHandIndex - 1, game.game_hands[gameHandIndex - 1].history!.length - 1)
        : undefined;

    return (
        <HistoryPanelDiv $replayMode={replayState.replayModeEnabled}>
            <ScoreDiv>
                <ScoreButton onClick={openScoreModal}>
                    {ourScore}&nbsp; | &nbsp;{theirScore}
                </ScoreButton>
                <DropdownMenuParent ref={dropdownRef}>
                    <DropdownButton onClick={toggleDropdown}>☰</DropdownButton>
                    {dropdownOpen && (
                        <HistoryPanelDropdown>
                            <DropdownItemButton onClick={leaveTable}>Leave table</DropdownItemButton>
                            <DropdownItemButton disabled={!requestUndoFunc} onClick={requestUndoFunc}>
                                {replayState.replayModeEnabled ? "Undo to here" : "Undo"}
                            </DropdownItemButton>
                            <DropdownItemButton disabled={game.status === GameStatus.COMPLETE} onClick={requestAbort}>
                                End game
                            </DropdownItemButton>
                        </HistoryPanelDropdown>
                    )}
                </DropdownMenuParent>
            </ScoreDiv>
            <HistoryPanelControls>
                {replayState.replayModeEnabled && (
                    <ReplayControlButton onClick={replayJumpBack} disabled={!canRewindHand && !canStepBackToPreviousHand}>
                        {"|←"}
                    </ReplayControlButton>
                )}
                <ReplayControlButton
                    onClick={() => (replayState.replayModeEnabled ? replayStepBack() : enterReplayModeAtMinusOne())}
                    disabled={replayState.replayModeEnabled && !canStepBack}
                >
                    {replayState.replayModeEnabled ? "←" : "← Replay"}
                </ReplayControlButton>
                {replayState.replayModeEnabled && (
                    <ReplayControlButton onClick={replayStepForward} disabled={!canStepForward}>
                        {"→"}
                    </ReplayControlButton>
                )}
                {replayState.replayModeEnabled && (
                    <ReplayControlButton onClick={replayJumpForward} disabled={!canFastForwardHand && !canStepForwardToNextHand}>
                        {"→|"}
                    </ReplayControlButton>
                )}
                {replayState.replayModeEnabled && <ReplayControlButton onClick={exitReplayMode}>{"X"}</ReplayControlButton>}
            </HistoryPanelControls>
            <ScrollableArea ref={scrollableAreaRef}>{renderHandHistory(handHistory, table.players)}</ScrollableArea>
            {playState.is_hand_over && renderScoreboard(playerNames, nextHandCallback, replayState.replayModeEnabled, isGameOver)}
        </HistoryPanelDiv>
    );
};
