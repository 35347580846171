import styled from "styled-components";
import { NavBar } from "../components/NavBar";

const ParentDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const MainDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    align-items: center;
    justify-content: center;
    align-content: center;
    border-radius: 16px;
    padding: 30px 50px 30px 50px;
    background-color: ${(props) => props.theme.colors.primary_hinted_white};
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: start;
    justify-content: center;
    align-content: center;
    text-align: left;
    margin: 8px;
`;

const Header = styled.h2`
    font-size: 24px;
    margin-block-end: 0;
    margin-block-start: 12px;
`;

const BigHeader = styled.h1`
    font-size: 36px;
    margin-block-end: 0;
    margin-block-start: 12px;
`;

export function AboutPage(): JSX.Element {
    return (
        <>
            <NavBar />

            <ParentDiv>
                <MainDiv>
                    <Section>
                        <BigHeader>About</BigHeader>
                    </Section>
                    <Section>
                        <p>
                            <b>playtaichu.org</b> is a hobby project by me, Sean McCarthy. I'm aiming to provide a superior way to play a
                            Tichu-like game digitally. Features include one-click combination plays, game replays, unlimited undo, optional
                            duplicate scoring, and AI opponents.
                        </p>
                        <p>
                            Tichu is one of my favorite games and is best played in person with real cards. But sometimes, playing in person
                            is not an option.
                        </p>
                        <p>
                            This website is still in development, and is very much a work in progress. It is only publicly accessible for
                            testing purposes. Please don't advertise it or rely on it.
                        </p>
                    </Section>
                    <Section>
                        <Header>Taichu vs Tichu</Header>
                    </Section>
                    <Section>
                        <p>
                            The game available here has slightly tweaked rules compared to Tichu. This is due to my belief that certain
                            aspects of Tichu work well in person but translate clumsily to the digital realm: specifically, the actions that
                            you can take out of turn.
                        </p>
                        <p>
                            In Taichu, bombing out of turn and making a bet out of turn are no longer possible. This allows gameplay to flow
                            smoothly and without worrying about reflexes. Note that the inability to bet outside your turn also includes the
                            removal of "Grand" bets.
                        </p>
                        <p>
                            These changes make Taichu a slightly simpler game, and I don't consider them to be gameplay improvements. I see
                            them as compromises to make the interface friendlier that I think are worth the cost in depth.
                        </p>
                    </Section>
                    <Section>
                        <Header>"Taichu"?</Header>
                    </Section>
                    <Section>
                        <p>
                            Because a few rules have been changed, I felt the most respectful option was to use a different name while still
                            alluding to the original. I think "Taichu" walks that line well.
                        </p>
                        <p>
                            <i>Tichu</i>, as I understand it, is a Chinese term meaning "propose" or "put forward" - an apt term for the bet
                            you can make at the start of a hand. <i>Taichu</i> is also Chinese (though more esoteric) and refers to
                            something like "the very beginning" or "the primordial beginning" in philosophy. I thought this was also an
                            appropriate name for a start-of-hand bet.
                        </p>
                    </Section>
                    <Section>
                        <Header>Credit</Header>
                    </Section>
                    <Section>
                        <p>
                            <a href="https://boardgamegeek.com/boardgame/215/tichu">Tichu</a> is a wonderful game by Urs Hostettler. I'm
                            just building an interface to play it, and deserve no real credit for the game's design.
                        </p>
                        <p>
                            As mentioned above, this website is still being developed, and is only publicly accessible for testing purposes.
                            I don't yet have permission from Urs Hostettler or Tichu's publishers - and while I don't think such permission
                            is legally necessary, I am committed to respecting the game's creators beyond the legal minimum. I intend to
                            discuss the preferences of Urs and other rightsholders with them, and make any necessary changes (including
                            making the entire site publicly unavailable permanently), before it's done.
                        </p>
                    </Section>
                    <Section>
                        <Header>Bots (AI Opponents)</Header>
                    </Section>
                    <Section>
                        <p>
                            The bots are a work in progress. Currently they cheat: during play of the hand (after exchanging cards), they
                            have full knowledge of all hands. One of my next steps is to replace that with generating possible hand
                            distributions and summing the bot's preferences over all generations.
                        </p>
                        <p>
                            Face up hands aside, the bots use techniques similar to AlphaZero. The basic idea is to have a
                            neural-network-assisted tree search. Tree search means that the bot tries to calculate out "if I do this, the
                            opponents will do that, and then I can do that, and then..." as deep as possible. These bots use a type of tree
                            search called Monte Carlo Tree Search (MCTS), which uses the heuristic of how promising a line of play is to
                            help decide which lines of play to go deeper down.
                        </p>
                        <p>
                            The tree search is assisted by neural networks in two ways. First, the "policy" network looks at a given
                            position and spits out weights for the different legal actions that represent what it thinks is the probability
                            that a perfect player would pick those respective actions. This can help inform the tree search, letting us
                            focus more on moves that are more likely to be good. Second, the "value" network can spit out rough guesses at
                            the value of a position, which we use in lieu of fully exploring a branch of the tree, as a sort of rough
                            estimate of how the game would turn out if we continued down that branch. This replaces the default heuristic in
                            MCTS of "what's the score if I play out the rest of the hand randomly".
                        </p>
                        <p>
                            The cool part is how we train these networks. We start by having the MCTS agents play a bunch of games against
                            each other, and then use those games as training data for the networks. This gives us some minimally competent
                            networks (they're able to predict mediocre choices made with some lookahead), and we can use them to enhance the
                            MCTS at the margin in order to generate better-played games. Those games feed back into network training, and so
                            on.
                        </p>
                        <p>
                            The current bots do 200 iterations of MCTS per move, which means they visit 200 different nodes in the search
                            tree. This number is primarily constrained by practical reasons - the server running this is not particularly
                            strong, and we don't want bot turns to take super long. There's a lot of room for the bots to play better just
                            by upping this number.
                        </p>
                    </Section>
                </MainDiv>
            </ParentDiv>
        </>
    );
}
