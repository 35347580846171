import { useState } from "react";
import { ApiClient } from "../client/ApiClient";
import styled from "styled-components";
import butterfly from "../assets/butterfly-black.svg";
import { DmAuthResponse } from "../client/server-types-python";

const LoginBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 100px;
    width: 400px;
    height: 400px;
    padding: 20px;
    border-style: dotted;
    border-width: 2px;
    border-radius: 8px;
    border-color: gray;
`;

const SimpleDiv = styled.div`
    margin: 3px;
`;

const LoginInfo = styled.div`
    font-size: 16px;
    padding: 0px 10px;
    font-style: italic;
`;

const FieldLabel = styled.label`
    font-size: 16px;
    padding: 0px 10px;
`;

export function Login(props: {
    apiClient: ApiClient;
    setLoggedInCallback: (dmAuthResponse: DmAuthResponse) => void;
    resetPasswordPageData: { email: string; token: string } | null;
}): JSX.Element {
    const isResetPasswordPage = props.resetPasswordPageData !== null;

    const [email, setEmail] = useState(isResetPasswordPage ? props.resetPasswordPageData!.email : "");
    const [password, setPassword] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [registerMode, setRegisterMode] = useState(false);
    const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
    const [postResetPasswordMode, setPostResetPasswordMode] = useState(false);

    const emailField = (
        <input
            disabled={isResetPasswordPage}
            type="text"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
        />
    );
    const passwordField = (
        <input type="password" id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
    );
    const registerCheckbox = (
        <input type="checkbox" id="register" name="register" value="register" onChange={(e) => setRegisterMode(e.target.checked)} />
    );
    const forgotPasswordCheckbox = (
        <input
            type="checkbox"
            id="forgot_password"
            name="forgot_password"
            value="forgot_password"
            onChange={(e) => setForgotPasswordMode(e.target.checked)}
        />
    );
    const displayNameField = (
        <input type="text" id="display_name" name="display_name" value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
    );

    const welcomeMessage = isResetPasswordPage ? "Welcome back. You can reset your password below." : "Log in to use the site.";

    return (
        <LoginBox>
            {postResetPasswordMode ? (
                <div>
                    An email is being sent to {email} with a link to reset your password. This normally takes less than a minute. Check your
                    spam folder if necessary.
                </div>
            ) : (
                <>
                    <SimpleDiv>
                        <LoginInfo>{welcomeMessage}</LoginInfo>
                    </SimpleDiv>
                    <SimpleDiv>
                        <img src={butterfly} alt="butterfly" height="30px" />
                    </SimpleDiv>

                    <SimpleDiv>
                        <FieldLabel>Email:</FieldLabel>
                        {emailField}
                    </SimpleDiv>
                    <SimpleDiv>
                        <FieldLabel>{isResetPasswordPage ? "New " : ""} Password:</FieldLabel>
                        {passwordField}
                    </SimpleDiv>
                    {!isResetPasswordPage && (
                        <SimpleDiv>
                            {registerCheckbox}
                            <FieldLabel>Create a new account</FieldLabel>
                        </SimpleDiv>
                    )}
                    {!registerMode && !isResetPasswordPage && (
                        <SimpleDiv>
                            {forgotPasswordCheckbox}
                            <FieldLabel>I forgot my password</FieldLabel>
                        </SimpleDiv>
                    )}
                    {registerMode && !isResetPasswordPage && (
                        <SimpleDiv>
                            <FieldLabel>Display Name:</FieldLabel>
                            {displayNameField}
                        </SimpleDiv>
                    )}
                    <SimpleDiv>
                        <button
                            onClick={() => {
                                if (isResetPasswordPage) {
                                    const changePasswordResponse = props.apiClient.changePasswordViaToken(
                                        email,
                                        password,
                                        props.resetPasswordPageData!.token
                                    );
                                    changePasswordResponse.then((response) => {
                                        if (response instanceof Error) {
                                            console.error(response);
                                        } else {
                                            props.setLoggedInCallback(response);
                                        }
                                    });
                                } else if (forgotPasswordMode && !registerMode) {
                                    const resetPasswordResponse = props.apiClient.resetPassword(email);
                                    if (resetPasswordResponse instanceof Error) {
                                        console.error(resetPasswordResponse);
                                    } else {
                                        setForgotPasswordMode(false);
                                        setPostResetPasswordMode(true);
                                    }
                                } else {
                                    const responsePromise = registerMode
                                        ? props.apiClient.register(email, password, displayName)
                                        : props.apiClient.login(email, password);
                                    responsePromise.then((response) => {
                                        if (response instanceof Error) {
                                            console.error(response);
                                        } else {
                                            props.setLoggedInCallback(response);
                                        }
                                    });
                                }
                            }}
                        >
                            {isResetPasswordPage
                                ? "Update Password and log in"
                                : registerMode
                                ? "Register"
                                : forgotPasswordMode
                                ? "Send me an email"
                                : "Log in"}
                        </button>
                    </SimpleDiv>
                </>
            )}
        </LoginBox>
    );
}
