import { AnimatePresence, motion } from "framer-motion";
import { CardId } from "../client/basic-types";
import { CardDetails } from "../model/CardDetails";
import { Card } from "./game/Card";

export const TradeCardSlot: React.FC<{
    cardId: CardId | null;
    direction: "left" | "center" | "right";
    oneCardSelected: boolean;
    onClick: () => void;
    contextId: string;
}> = ({ cardId, direction, oneCardSelected, onClick, contextId }) => {
    const details = cardId !== null ? CardDetails.getCardDetails(cardId) : null;

    return (
        <div className="tradeCardSlot">
            <AnimatePresence mode="popLayout">
                {details && (
                    <motion.div key={details.cardId} animate={{ x: 0, y: 0 }} transition={{ duration: 0.5, ease: "circOut" }}>
                        <Card
                            cardDetails={details}
                            onClick={onClick}
                            onClickWithNoCardDetails={onClick}
                            tradeDirection={direction}
                            textOnly={false}
                            contextId={contextId}
                        />
                    </motion.div>
                )}
                {!details && (
                    <motion.div key={"arrowContainer"} initial={false} exit={{ opacity: 0 }} transition={{ delay: 0.1, duration: 0 }}>
                        <Card
                            cardDetails={null}
                            onClick={onClick}
                            onClickWithNoCardDetails={onClick}
                            tradeDirection={direction}
                            textOnly={false}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};
